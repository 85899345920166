<div #tester style="width: 1vw;">

</div>
<button  id='check' mat-icon-button [matMenuTriggerFor]="menu" class='back-btn' style="display: none;">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu [dir]="dir" #menu="matMenu">
  <button mat-menu-item (click)="logoutFromActivity()">
      <mat-icon >autorenew</mat-icon>
      <span>{{translation.switchActivity[language]}}</span>
  </button>
  <button mat-menu-item (click)="logoutFromAccount()">
      <mat-icon >login</mat-icon>
      <span>{{translation.logoutFromAccount[language]}}</span>
  </button>
</mat-menu>
<div class="loading-spin-wrapper">
  <mat-spinner class="loadingSpin" *ngIf="loading"></mat-spinner>
</div>
<img (click)="moveToLeaderBoard()" class="leaderboardButton" src="assets/images/score-btn.png" *ngIf="haveLeaderBoard"/>

<div *ngIf="!loading" style="justify-content: center;align-content: center;display: flex;margin-top:2vh;height:68% ;width: 100%; margin-left: auto; margin-right: auto; overflow-y: scroll;overflow-x: hidden;">
  <div style="background-image: url('assets/images/tree.svg');background-size: cover;position: relative;width: 50vw;" [ngStyle]="getLineStyle()">
      <div class="components">
        <mat-list-item *ngFor="let component of components; let i = index">
          <!-- <div (click)='showComponent(i)' class="component" [ngClass]="{'left': i%2===0,'right': i%2===1}">
            <div #title [ngStyle]="getTitleStyle(title,tester)" style="line-height: 4vw;font-size:4vw;left: -2.5vw;;position: absolute;width: 25vw;text-align: center;">{{component.configurations["header"]}}</div>
            <div class="componentIndex" >{{i+1}}</div>
            <img [ngClass]="{'greyscale': i !== currIndex}" style='height: auto;width: 100%;' [src]="'assets/images/component' + i%3 + '.png'">
          </div> -->
          <div (click)='showComponent(i)' class="component" [ngClass]="{'left': i%2===0,'right': i%2===1}">
            <div [class]="i === currIndex ? 'component-index-outside purple-bg': 'component-index-outside turquoise-bg'"></div>
            <div [class]="i === currIndex ? 'componentIndex purple-bg-dark': 'componentIndex turquoise-bg-dark'" >{{i+1}}</div>
            <div class="component-title">
              <span #title>{{component.configurations["header"]}}</span>
            </div>           
          </div>
        </mat-list-item >
      </div>
    </div>
</div>
