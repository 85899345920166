import { Observable } from 'rxjs';
import { Node } from './../.screens/login/login.component';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import { first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private firestore: AngularFirestore, private auth: AngularFireAuth) { }

  login(): Promise<User>{
    return this.auth.authState.pipe(first()).toPromise().then( user => {
      return user;
    });
  }
  logout(): Promise<any>{
    localStorage.clear();
    return this.auth.signOut();
  }
  getChildren(doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>, parentNode: Node): Promise<Node[]>{
    const promises: Promise<any>[] = [];
    const tree: Node[] = [];
    return doc.ref.collection('children').get().then(task => {
      task.forEach(doc1 => {
        const node = {name: doc1.id, children: [], parent: parentNode};
        promises.push(this.getChildren(doc1, node).then( children => {
          node.children = children;
          tree.push(node);
        }));
      });
      return Promise.all(promises).then(() => {
        return tree;
      });
    });
  }

  getTreeData(codeValue): Promise<Node[]>{
    let customerUID;
    const promises: Promise<any>[] = [];
    const tree: Node[] = [];
    return this.firestore.collection('activities').doc(codeValue).get().toPromise().then(doc => {
      customerUID = doc.data().customerUID;
      return this.firestore.collection('customers').doc(customerUID).collection('structure').get().toPromise().then(task => {
        task.forEach( doc1 => {
          const node = {name: doc1.id, children: [], parent: null};
          promises.push(this.getChildren(doc1, node).then( children => {
            node.children = children;
            tree.push(node);
          }));
        });
        return Promise.all(promises).then(() => {
          return tree;
        });
      });
    });
  }
}
