// tslint:disable: max-line-length

import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as texts from '../../../../assets/languages';
import { LoginService } from '../../../services/login.service';
import { AreYouDialogComponent } from '../../dialogs/are-you-dialog/are-you-dialog.component';
import { ComponentModel } from '../course-dashboard/course-dashboard.component';
import { ExplainDialogComponent } from '../explain-dialog/explain-dialog.component';
import { DashboardService } from './../../../services/dashboard.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-course-end',
  templateUrl: './course-end.component.html',
  styleUrls: ['./course-end.component.css']
})
export class CourseEndComponent implements OnInit {
  language;
  translation;
  dir = 'rtl';
  user;
  currIndex;
  components: Array<ComponentModel> = new Array();
  activityCode;
  loading = true;
  endTime;
  haveLeaderBoard = false;
  constructor(@Inject(DOCUMENT) private document: Document,
              private firestore: AngularFirestore,
              public dialog: MatDialog,
              private router: Router,
              private snackBar: MatSnackBar,
              private loginService: LoginService,
              private dashboardService: DashboardService) {
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
    this.loginService.login().then(user => {
      this.user = user;
      if (!(user && user.uid)){
        window.location.reload();
      }
      this.dashboardService.setUser(user);
      this.dashboardService.getActivityData().then(doc => {
        if(doc.data().haveLeaderBoard !== null){
          this.haveLeaderBoard = doc.data().haveLeaderBoard
        }
        this.activityCode = doc.id;
        this.endTime = doc.data()['end time'].toDate();
        this.setScreen(doc.data().layoutId, doc.data().customerUID);
      });
    })
   }
   setScreen(layoutId: string, customerId: string): void{
    this.dashboardService.setCourseScreen(layoutId, customerId, this.activityCode).then(data => {
      this.components = data.localComponents;
      this.currIndex =  data.currComponentIndex;
      if (this.currIndex < this.components.length && this.endTime > new Date()){
        this.router.navigateByUrl('dashboard/course-map');
      }
      this.loading = false;
    });
  }
  moveToLeaderBoard(): void{
    this.router.navigateByUrl("leaderboard")
  }
  logoutFromActivity(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.leaveActivityTitle[this.language],
              content: this.translation.leaveActivityContent[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.firestore.collection('users').doc(this.user.uid).set({}).then(() => {
          localStorage.removeItem('tempCode');
          window.location.reload();
        });
      }
    });
  }

  logoutFromAccount(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.logoutTitle[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.loginService.logout().then(() => {
          window.location.reload();
        });
      }
    });
  }
  ngOnInit(): void {
  }

}
