
<div [dir]="dir" style="width: 100%; height: 100%; overflow: hidden; position: absolute; background-color: #e9e9f4;">
    <!-- <img src="assets/images/background.png" style="position: absolute; z-index: -1;" height="100%" width="100%"> -->
    <app-header [showMenuButtons] ='showMenuButtons' [activityName]="activityName" [activityLogo]="activityLogo"></app-header>
    <button id='back' (click)="logoutFromAccount()" mat-mini-fab color="primary" class='back-btn' style="display:none;">
      <mat-icon>login</mat-icon>
    </button>
    <div style="height:63% ;width: 100%; display: block; margin-left: auto; margin-right: auto; overflow-y: scroll;">
        <mat-vertical-stepper [linear]="isLinear" #stepper>

            <mat-step editable="false" [stepControl]="codeFormControl">
              <form [formGroup]="codeFormControl">
                <ng-template matStepLabel>
                   {{codeLabel}}
                   <button mat-stroked-button color="primary" *ngIf="!changeDisabled" (click)="changeCode()">{{translation['switch'][language]}}</button>
                  </ng-template>
                <mat-form-field >
                  <mat-label>{{translation['code'][language]}}</mat-label>
                  <input #codeInput matInput [formControl]="codeFormControl" (focusout)="codeFocusOut()" placeholder="{{translation['codeExample'][language]}}" required>
                  <mat-error *ngIf="codeFormControl.hasError('invalidCode') && !codeFormControl.hasError('required')">
                    {{translation['codeNotFound'][language]}}
                  </mat-error>
                  <mat-error *ngIf="codeFormControl.hasError('required')">
                    {{translation['mustFillCode'][language]}}
                  </mat-error>
                </mat-form-field>
                <div>
                  <button mat-button color="primary" [disabled]="btnDisabled" (click)="submitCode(stepper,codeInput.value)">{{translation['next'][language]}}</button>
                  <mat-progress-bar *ngIf="!progressBarDisabled" mode="indeterminate"></mat-progress-bar>
                </div>
              </form>
            </mat-step>

            <mat-step [editable]="!isLoading" *ngIf="loginType==='hierarchy'" [stepControl]="groupFormControl">
              <form>
                <ng-template matStepLabel>{{groupLabel}}</ng-template>
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                    <!-- This is the tree node template for leaf nodes -->
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                        <li class="mat-tree-node">
                        <!-- use a disabled button to provide padding for tree leaf -->

                        <button mat-button color="primary" (click)="groupClicked(stepper,node)">{{node.name}}</button>

                        </li>
                    </mat-tree-node>
                    <!-- This is the tree node template for expandable nodes -->
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                        <div class="mat-tree-node">
                            <button mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                            </button>
                            {{node.name}}
                        </div>
                        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
              </form>
            </mat-step>

            <mat-step [editable]="!isLoading" *ngIf="loginType==='groups'" [stepControl]="groupFormControl">
              <form [formGroup]="groupFormControl">
                <ng-template matStepLabel>{{groupLabel}}</ng-template>
                <mat-form-field>
                  <mat-label>{{translation['chooseGroup'][language]}}</mat-label>
                  <mat-select class='noSelect' #groupSelect (selectionChange)="submitGroup(groupSelect.value)" [formControl]="groupFormControl" required>
                    <mat-option *ngFor="let group of groups" [value]="group">
                      {{translation['group'][language]}} {{group}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="groupFormControl.hasError('required')">
                    {{translation['mustChooseGroup'][language]}}
                  </mat-error>
                </mat-form-field>
                <div>
                  <button mat-button color="primary" (click)="goForward(stepper)">{{translation['next'][language]}}</button>
                </div>
              </form>
            </mat-step>

            <mat-step [editable]="!isLoading" *ngIf="isNameRequired" [stepControl]="nameControl">
                <form [formGroup]="nameControl">
                  <ng-template matStepLabel>{{nameLabel}}</ng-template>
                  <mat-form-field>
                    <mat-label>{{translation['name'][language]}}</mat-label>
                    <input matInput #nameInput (focusout)="nameFocusOut(nameInput.value)" [value]="currName" [formControl]="nameControl" placeholder="{{translation['nameExample'][language]}}" required>
                  </mat-form-field>
                  <div>
                    <button mat-button color="primary" matStepperPrevious>{{translation['previous'][language]}}</button>
                    <button mat-button color="primary" (click)="submitName(stepper,nameInput.value)">{{translation['next'][language]}}</button>
                  </div>
                </form>
            </mat-step>

            <mat-step [editable]="!isLoading">
              <ng-template matStepLabel>{{translation['finish'][language]}}</ng-template>
              <p>{{translation['allTrue'][language]}}</p>
              <div>
                <button mat-button color="primary" matStepperPrevious [disabled]="isLoading">{{translation['previous'][language]}}</button>
                <button mat-button color="primary" (click)="submitAll()">{{translation['finish'][language]}}</button>
                <mat-progress-bar *ngIf="isLoading" style="margin-inline-start: 64px;" mode="indeterminate"></mat-progress-bar>
              </div>
            </mat-step>

        </mat-vertical-stepper>
    </div>
    <div class="footer-logo-wrapper">
      <img src="assets/images/shortenLogo.png">
    </div>

</div>
