
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { AreYouDialogComponent } from 'src/app/.components/dialogs/are-you-dialog/are-you-dialog.component';
import * as texts from 'src/assets/languages';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dir = 'rtl';
  language;
  translation;
  activityName = '';
  activityLogo = '';
  currentUser;
  activityType;
  constructor(private firestore: AngularFirestore, private router: Router, private loginService: LoginService, public dialog: MatDialog) {
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
    this.loginService.login().then(user => {
      if (user && user.uid){
        this.firestore.collection('users').doc(user.uid).get().toPromise().then(userDoc => {
          if (userDoc.data().currActivity == null){
             window.location.reload();
          }
          else{
            this.firestore.collection('activities').doc(userDoc.data().currActivity).get().toPromise().then(doc => {
              if (doc.data().activityType === 'games' && doc.data()['end time'].toDate() < new Date()){
                router.navigateByUrl('leaderboard');
              }
              this.activityName = doc.data().activityName;
              this.activityLogo = doc.data().activityLogo;
              this.activityType = doc.data().activityType;
              /* second condition is to prevent a case when an activity is closed manually before scheduled end time during playing */
              if (router.url.split('/').length !== 3 && doc.data()['end time'].toDate() >= new Date()  ){
                if (this.activityType === 'course'){
                  router.navigateByUrl('dashboard/course-map');
                }
                else{
                  router.navigateByUrl('dashboard/games');
                }
              }
            });
          }
        });
      }
      else{
        router.navigateByUrl('login');
      }
    });
  }

  ngOnInit(): void {
  }

}
