import { Component, OnInit, Inject } from '@angular/core';
import {FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult} from 'firebaseui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as texts from 'src/assets/languages';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  dir = 'rtl';
  language;
  translation;
  constructor(public dialogRef: MatDialogRef<SigninComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
   }
  ngOnInit(): void {
  }

  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult): void {
    this.dialogRef.close(signInSuccessData);
  }

  errorCallback(errorData: FirebaseUISignInFailure): void {
      window.location.reload();
  }
}
