import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as texts from 'src/assets/languages';

@Component({
  selector: 'app-are-you-dialog',
  templateUrl: './are-you-dialog.component.html',
  styleUrls: ['./are-you-dialog.component.css']
})
export class AreYouDialogComponent implements OnInit {
  dir = 'rtl';
  language;
  translation;
  constructor(public dialogRef: MatDialogRef<AreYouDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
   }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
