// Copyright © Marvsoft LLP | All Rights Reserved

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appPackage]'
})
export class PackageDirective {

  constructor( public viewContainerRef: ViewContainerRef ) {}
}
