<div *ngIf='!loading'>
    <button class="next-btn" (click)="next()" mat-raised-button 
        *ngIf="components[currIndex].componentId !== 'ruvelQ6mbSE1Nm1W0dkc' 
        && !components[currIndex].configurations['gameId']">
        <mat-icon>keyboard_arrow_left</mat-icon>   
        {{translation.next[language]}}
    </button>
    <div class="loading-spin-wrapper">
        <mat-spinner class="loadingSpin" *ngIf="loading"></mat-spinner>
    </div>
    <div class="div-title-wrapper">
        <h1>{{components[currIndex].configurations['header']}}</h1>
    </div>
    <div class="component">
        <div class="component-index-outside-side"></div>
        <div class="componentIndex">{{currIndex + 1}}</div>      
    </div>
</div>
<div [dir]="dir" *ngIf='!loading' class="div-card-wrapper">  
    <mat-card class="contentCard">        
        <div dir="auto" class="card-title-wrapper">{{components[currIndex].configurations['description']}}</div>
        <div>
            <img *ngIf="components[currIndex].componentId === '5KoJq3O7vAW7bRxwvrY7'" class="card-image" [src]="components[currIndex].configurations['url']">
        </div>
        <div class="card-buttons-wrapper">
            <button class="button-general button-general-center" 
                *ngIf="components[currIndex].componentId === 'U3yrqsw4kEGlJgkPeZxo'" 
                (click)="moveToLink(components[currIndex].configurations['link'])" >
                {{translation.enterVideo[language]}}
            </button>
            <button class="button-general button-general-center" 
                *ngIf="components[currIndex].componentId === '5qXp60HM1svkMPK0RW8i'" 
                (click)="moveToLink(components[currIndex].configurations['url'])" >
                {{translation.enterPresentation[language]}}
            </button>
            <!-- <button style="margin:auto;display: block;margin: auto;margin-top: 1em;" 
            (click)="next()" mat-raised-button color="primary">
                {{components[currIndex].configurations['gameId'] ? 
                translation.enterGame[language] : components[currIndex].componentId === 'ruvelQ6mbSE1Nm1W0dkc' ? 
                translation.enterSurvey[language] : translation.next[language]}}
            </button> -->
            <button class="button-general button-general-center" 
                *ngIf="components[currIndex].componentId === 'ruvelQ6mbSE1Nm1W0dkc'" 
                (click)="next()" >
                {{translation.enterSurvey[language]}}
            </button>
            <button class="button-general button-general-center" 
                *ngIf="components[currIndex].configurations['gameId']" 
                (click)="next()" >
                {{translation.enterGame[language]}}
            </button>
        </div>
    </mat-card>
</div>
