<!-- Copyright © Marvsoft LLP | All Rights Reserved -->

<div class="cInformation">
    <div class="cLogo"></div>
    <div (click)='back()' class="cClose">X</div>

    <div class="cTime">
        <span id="iMin">{{strMin}}</span><b> : </b>
        <span id="iSec">{{strSec}}</span>
    </div>
    <div class="cStage">
        <!-- <img style="height: 50px; width: 50px;" src="assets/images/emptybutton.png"> -->
        {{GlobalsService.pairStage + '/' + nTotalStage}}
    </div>
    <div class="cStage"></div>
    <div class="cTitle">זוגות</div>
</div>

<div class="cProgressHolder">
    <div class="cProgressCtrl">
        <div id="iScoreCounts" class="cProgressBg">{{getScore()}}</div>
        <div id="iMoveCounts" class="cProgressBg">{{GlobalsService.pairMoves}}</div>
        <div id="iPairsCounts" class="cProgressBg">{{GlobalsService.pairPairs}}</div>
    </div>

    <div class="cProgressTitle">
        <div class="cProgressCmn">ציון</div>
        <div class="cProgressCmn">מהלכים</div>
        <div class="cProgressCmn">זוגות</div>
    </div>
</div>
