import { FinalScreenComponent } from './.games/matches/final-screen/final-screen.component';
import { MSLevelDirective } from './.games/matches/matches-puzzle/ms-level.directive';
import { MSLevelComponent } from './.games/matches/ms-level/ms-level.component';
import { MatchesPuzzleComponent, MatchesPopupDialog } from './.games/matches/matches-puzzle/matches-puzzle.component';
import { FactoryService } from './services/factory.service';
import { PackageDirective } from './.games/factory-game/factory/package.directive';
import { FactoryComponent, PopupDialog } from './.games/factory-game/factory/factory.component';
import { PackageComponent } from './.games/factory-game/package/package.component';
import { WatchComponent } from './.games/pair-game/watch/watch.component';
import { PieceHolderComponent, ConfirmationDialog } from './.games/pair-game/piece-holder/piece-holder.component';
import { GameBoardComponent } from './.games/pair-game/game-board/game-board.component';
import { DashboardService } from './services/dashboard.service';
import { GlobalsService } from './services/globals.service';
import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {FirebaseUIModule} from 'firebaseui-angular';
import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';


import {MatTreeModule} from '@angular/material/tree';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import { ExplainDialogComponent } from './.components/games.components/explain-dialog/explain-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSliderModule} from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';

import { DashboardComponent } from './.screens/dashboard/dashboard.component';
import { TrainingGamesComponent } from './.components/games.components/training-games/training-games.component';
import { TrainingFooterComponent } from './.components/footer.components/training-footer/training-footer.component';

import { LoginComponent } from './.screens/login/login.component';
import { HeaderComponent } from './.components/header/header.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { LeaderboardComponent } from './.screens/leaderboard/leaderboard.component';
import { AreYouDialogComponent } from './.components/dialogs/are-you-dialog/are-you-dialog.component';
import {MatCardModule} from '@angular/material/card';

import { LoginService } from './services/login.service';
import { SigninComponent } from './.components/dialogs/signin/signin.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CourseDashboardComponent } from './.components/games.components/course-dashboard/course-dashboard.component';
import { ComponentScreenComponent } from './.components/games.components/component-screen/component-screen.component';
import { CourseEndComponent } from './.components/games.components/course-end/course-end.component';
import { SatisfactionSurveyComponent } from './.games/satisfaction-survey/satisfaction-survey.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { TrueFalseComponent } from './.games/true-false/true-false.component';
import { HttpClientModule } from "@angular/common/http";
const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    },
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TrainingGamesComponent,
    TrainingFooterComponent,
    ExplainDialogComponent,
    LoginComponent,
    HeaderComponent,
    LeaderboardComponent,
    AreYouDialogComponent,
    GameBoardComponent,
    PieceHolderComponent,
    WatchComponent,
    ConfirmationDialog,
    SigninComponent,
    PackageComponent,
    FactoryComponent,
    PackageDirective,
    PopupDialog,
    CourseDashboardComponent,
    ComponentScreenComponent,
    CourseEndComponent,
    SatisfactionSurveyComponent,
    NotfoundComponent,
    MatchesPuzzleComponent,
    MSLevelComponent,
    MSLevelDirective,
    FinalScreenComponent,
    MatchesPopupDialog,
    TrueFalseComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    MatTreeModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatGridListModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatListModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    MatMenuModule,
    DragDropModule,
    MatCardModule,
    MatSliderModule,
    MatRadioModule,
    HttpClientModule
  ],
  providers: [GlobalsService, DashboardService, LoginService, FactoryService],
  bootstrap: [AppComponent]
})
export class AppModule { }
