import { gamesID } from './../../../.components/games.components/explain-dialog/explain-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
// Copyright © Marvsoft LLP | All Rights Reserved

import { Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MSLevelComponent } from '../ms-level/ms-level.component';
import { LevelData } from './level-data';
import { MSLevelDirective } from './ms-level.directive';
import { StickStyle } from './stick-style';
import { PuzzleType } from './puzzle-type';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as gameService from '../../../../assets/environments/service';

@Component({
  selector: 'app-matches-puzzle',
  templateUrl: './matches-puzzle.component.html',
  styleUrls: ['./matches-puzzle.component.scss'],
})
export class MatchesPuzzleComponent implements OnInit {
  viewContainerRef: ViewContainerRef;
  @ViewChild(MSLevelDirective, { static: true }) msLevelDirective: MSLevelDirective;
  components: any = [];

  isGameOver: boolean = false;
  isMuted: boolean = false;
  nMoves: number = 0;
  nCurLevel: number = 0;
  arrLevels: LevelData[] = [
    /* Level 1 data - Square:  Remove 4 matches to get 1 square*/
    new LevelData('הזז 4 גפרורים כדי לקבל ריבוע אחד', PuzzleType.SQUARE, new StickStyle(
       [
        [1, 1],
        [1, 1],
        [1, 1]
      ],
      [
        [1, 1, 1],
        [1, 1, 1]
      ]), [new StickStyle(
        [
         [1, 1],
         [0, 0],
         [1, 1]
       ],
       [
         [1, 0, 1],
         [1, 0, 1]
       ]
       )]),

     /* Level 2 data - Triangle: Remove 2 matches to get 4 triangles */
     new LevelData('הזז 2 גפרורים כדי לקבל 4 משולשים', PuzzleType.TRIANGLE, new StickStyle(
      [
        [-1, -1, -1],
        [-1, 0, -1],
        [1, 1, -1],
        [1, 1, 1]
      ], 
      null, 
       [
        [-1, -1, 1, -1],
        [-1, -1, 1, -1],
        [-1, 1, 1, 1]
      ],
      [
        [-1, 1, -1],
        [1, -1, -1],
        [1, 1, 1]
      ]), [new StickStyle(
        [
          [-1, -1, -1],
          [-1, 0, -1],
          [0, 0, -1],
          [1, 1, 1]
        ], 
        null, 
        [
          [-1, -1, 1, -1],
          [-1, -1, 1, -1],
          [-1, 1, 1, 1]
        ],
        [
          [-1, 1, -1],
          [1, -1, -1],
          [1, 1, 1]
        ]),
        new StickStyle([
          [-1, -1, -1],
          [-1, 0, -1],
          [1, 1, -1],
          [1, 1, 1]
        ],
        null,
         [
          [-1, -1, 1, -1],
          [-1, -1, 1, -1],
          [-1, 1, 0, 1]
        ],
        [
          [-1, 1, -1],
          [1, -1, -1],
          [1, 0, 1]
        ]
       )], 1),
    /* Level 3 data - Triangle: Move 1 matches to get 4 triangles*/
    new LevelData('הזז גפרור אחד בכדי לקבל 4 משולשים', PuzzleType.TRIANGLE, new StickStyle(
      [
        [1, 1],
        [1, -1],
        [1, 1]
      ], 
      null, 
       [
        [1, 0],
        [-1, 1, 1]
      ],
      [
        [1, 1],
        [1, 0]
      ]), [new StickStyle(
        [
          [1, 1],
          [1, -1],
          [1, 1]
        ], 
        null, 
         [
          [1, 0],
          [-1, 1, 1]
        ],
        [
          [0, 1],
          [1, 1]
        ]
       ), 
       new StickStyle(
        [
          [1, 1],
          [1, -1],
          [1, 1]
        ], 
        null, 
         [
          [1, 1],
          [-1, 0, 1]
        ],
        [
          [1, 1],
          [1, 0]
        ]
       )], 0, 1),
       
    /* Level 4 data - Numeric: Remove 3 matches to fix this equation */
    new LevelData('הזז 3 גפרורים בכדי לפתור את המשוואה', PuzzleType.NUMERIC, new StickStyle(
      [
       [1, -1 , -1, -1, 1],
       [1, -1 , 2, -1, -1],
       [1, -1 , -1, -1, 0],
       [-1, -1, 0, -1 , 1],
       [3, -1, 0, -1 , 1],
       [-1, -1, 0, -1 , 1]
     ],
     [
       [1, 1 , -1, -1, -1, 1],
       [1, 1 , -1, -1, -1, 1],
       [-1, -1 , -1, -1, -1, -1],
       [-1, -1 , 0, 1, 1, 1],
       [-1, -1 , 0, 1, 1, 1],
     ]), 
     [new StickStyle(
      [
        [1, -1 , -1, -1, 0],
        [1, -1 , 2, -1, -1],
        [1, -1 , -1, -1, 0],
        [-1, -1, 0, -1 , 1],
        [3, -1, 0, -1 , 0],
        [-1, -1, 0, -1 , 1]
      ],
      [
        [1, 1 , -1, -1, -1, 1],
        [0, 1 , -1, -1, -1, 1],
        [-1, -1 , -1, -1, -1, -1],
        [-1, -1 , 0, 1, 1, 1],
        [-1, -1 , 0, 1, 1, 1],
      ]),
      new StickStyle(
        [
         [1, -1 , -1, -1, 1],
         [1, -1 , 2, -1, -1],
         [1, -1 , -1, -1, 0],
         [-1, -1, 0, -1 , 1],
         [3, -1, 0, -1 , 1],
         [-1, -1, 0, -1 , 1]
       ],
       [
         [1, 0 , -1, -1, -1, 1],
         [1, 1 , -1, -1, -1, 1],
         [-1, -1 , -1, -1, -1, -1],
         [-1, -1 , 0, 1, 0, 1],
         [-1, -1 , 0, 1, 0, 1],
       ]),
       new StickStyle(
        [
         [1, -1 , -1, -1, 1],
         [1, -1 , 2, -1, -1],
         [1, -1 , -1, -1, 0],
         [-1, -1, 0, -1 , 1],
         [3, -1, 0, -1 , 0],
         [-1, -1, 0, -1 , 1]
       ],
       [
         [0, 1 , -1, -1, -1, 1],
         [0, 1 , -1, -1, -1, 1],
         [-1, -1 , -1, -1, -1, -1],
         [-1, -1 , 0, 1, 1, 1],
         [-1, -1 , 0, 1, 1, 1],
       ]),
       new StickStyle(
        [
         [1, -1 , -1, -1, 1],
         [1, -1 , 2, -1, -1],
         [0, -1 , -1, -1, 0],
         [-1, -1, 0, -1 , 1],
         [3, -1, 0, -1 , 1],
         [-1, -1, 0, -1 , 1]
       ],
       [
         [1, 1 , -1, -1, -1, 1],
         [0, 1 , -1, -1, -1, 1],
         [-1, -1 , -1, -1, -1, -1],
         [-1, -1 , 0, 1, 1, 0],
         [-1, -1 , 0, 1, 1, 1],
       ]),
    ]),

  /* Level 5 data - Triangle:Add 2 matches to get 8 triangles */
  new LevelData('הוסף 2 גפרורים בכדי לקבל 8 משולשים', PuzzleType.TRIANGLE, new StickStyle(
    [
      [0, 0, -1],
      [0, 1, 0],
      [0, 0, -1],
      [1, 1, 1]
    ], 
    null, 
    [
      [-1, 0, 1, 0],
      [0, 0, 1],
      [-1, 1, 1, 1]
    ],
    [
      [0, 1, 0],
      [1, 0, 0],
      [1, 1, 1]
    ]
    ), [new StickStyle(
      [
        [0, 0, -1],
        [0, 1, 0],
        [0, 0, -1],
        [1, 1, 1]
      ], 
      null, 
      [
        [-1, 0, 1, 0],
        [0, 1, 1],
        [-1, 1, 1, 1]
      ],
      [
        [0, 1, 0],
        [1, 1, 0],
        [1, 1, 1]
      ]
    )], 1, 0, 2),
    
  /* Level 6 data - Triangle: Remove 2 matches to get 5 triangles */
  new LevelData('הזז 2 גפרורים בכדי לקבל 5 משולשים', PuzzleType.TRIANGLE, new StickStyle(
    [
      [1, 1, 1],
      [1, 1, -1],
      [-1, 1, -1]
    ],
    null,
    [
      [1, -1, 1],
      [-1, 1, 1],
      [-1, 1, -1]
    ],
    [
      [1, -1, 1],
      [-1, 1, 1],
      [-1, 1, -1]
    ]),
    [new StickStyle(
      [
        [1, 1, 1],
        [0, 0, -1],
        [-1, 1, -1]
      ],
      null,
      [
        [1, -1, 1],
        [-1, 1, 1],
        [-1, 1, -1]
      ],
      [
        [1, -1, 1],
        [-1, 1, 1],
        [-1, 1, -1]
      ]),
      new StickStyle(
        [
          [1, 1, 1],
          [1, 1, -1],
          [-1, 1, -1]
        ],
        null,
        [
          [1, -1, 1],
          [-1, 1, 0],
          [-1, 1, -1]
        ],
        [
          [1, -1, 1],
          [-1, 0, 1],
          [-1, 1, -1]
        ]),
      new StickStyle(
        [
          [1, 1, 1],
          [1, 1, -1],
          [-1, 1, -1]
        ],
        null,
        [
          [1, -1, 0],
          [-1, 1, 0],
          [-1, 1, -1]
        ],
        [
          [1, -1, 1],
          [-1, 1, 1],
          [-1, 1, -1]
        ]),
      new StickStyle(
        [
          [1, 1, 1],
          [1, 1, -1],
          [-1, 1, -1]
        ], 
        null, 
        [
          [1, -1, 1],
          [-1, 1, 1],
          [-1, 1, -1]
        ],
        [
          [0, -1, 1],
          [-1, 0, 1],
          [-1, 1, -1]
        ])
    ], 0, 1),
  /* Level 7 data - Triangle: Add 2 matches to get 7 triangles */
  new LevelData('הוסף 2 גפרורים בכדי לקבל 7 משולשים', PuzzleType.TRIANGLE, new StickStyle(
    [
      [0, 0, -1],
      [0, 1, 0],
      [0, 0, -1],
      [1, 1, 1]
    ],
    null,
    [
      [-1, 0, 1, 0],
      [0, 0, 1],
      [-1, 1, 0, 1]
    ],
    [
      [0, 1, 0],
      [1, 1, 0],
      [1, 1, 0]
    ]),
     [new StickStyle(
      [
        [0, 0, -1],
        [0, 1, 0],
        [1, 0, -1],
        [1, 1, 1]
      ],
      null,
      [
        [-1, 0, 1, 0],
        [0, 1, 1],
        [-1, 1, 0, 1]
      ],
      [
        [0, 1, 0],
        [1, 1, 0],
        [1, 1, 0]
      ]),
    new StickStyle(
      [
        [0, 0, -1],
        [0, 1, 0],
        [0, 0, -1],
        [1, 1, 1]
      ],
      null,
      [
        [-1, 0, 1, 0],
        [0, 1, 1],
        [-1, 1, 1, 1]
      ],
      [
        [0, 1, 0],
        [1, 1, 0],
        [1, 1, 0]
      ])
    ], 1, 0, 2),
    /* Level 8 data - Square: Move 3 matches to get 2 squares */
    new LevelData('הזז 3 גפרורים בכדי לקבל 2 ריבועים', PuzzleType.SQUARE, new StickStyle(
       [
        [1, 1],
        [1, 1],
        [1, 1],
        [0, 0]
      ],
      [
        [1, 0, 1],
        [1, 1, 1],
        [0, 0, 0]
      ]), 
      [new StickStyle(
        [
          [1, 1],
          [0, 0],
          [1, 1],
          [0, 1]
        ],
        [
          [1, 0, 1],
          [1, 0, 1],
          [0, 1, 1]
        ]),
        new StickStyle(
          [
           [1, 1],
           [0, 0],
           [1, 1],
           [1, 0]
         ],
         [
           [1, 0, 1],
           [1, 0, 1],
           [1, 1, 0]
         ])
      ]),
    /* Level 9 data - Square: Move 3 matches to get 3 squares */
    new LevelData('הזז 3 גפרורים בכדי לקבל 3 ריבועים', PuzzleType.SQUARE, new StickStyle(
       [
        [0, 0 , 0],
        [1, 1 , 1],
        [1, 1 , 1],
        [0, 0 , 0]
      ],
      [
        [0, 1, 1, 0],
        [0, 1, 1, 0],
        [0, 1, 1, 0]
      ]),
      [new StickStyle(
        [
          [0, 1 , 0],
          [1, 1 , 1],
          [1, 0 , 1],
          [0, 0 , 0]
        ],
        [
          [0, 1, 1, 0],
          [1, 1, 1, 1],
          [0, 0, 0, 0]
        ]
       ),
       new StickStyle(
        [
         [0, 1 , 0],
         [1, 1 , 0],
         [1, 1 , 0],
         [0, 1 , 0]
       ],
       [
         [0, 1, 1, 0],
         [1, 1, 0, 0],
         [0, 1, 1, 0]
       ]),
       new StickStyle(
        [
         [0, 1 , 0],
         [0, 1 , 1],
         [0, 1 , 1],
         [0, 1 , 0]
       ],
       [
         [0, 1, 1, 0],
         [0, 0, 1, 1],
         [0, 1, 1, 0]
       ]),
       new StickStyle(
        [
         [0, 0 , 0],
         [1, 0 , 1],
         [1, 1 , 1],
         [0, 1 , 0]
       ],
       [
         [0, 0, 0, 0],
         [1, 1, 1, 1],
         [0, 1, 1, 0]
       ])
      ]),
    /* Level 10 data Triangle: Remove 4 matches to get 3 triangles */
    new LevelData('הזז 4 גפרורים בכדי לקבל 3 משולשים', PuzzleType.TRIANGLE, new StickStyle(
      [
        [1, -1, -1],
        [1, 1],
        [1, 1]
      ],
      null,
       [
        [1, 1, -1],
        [-1, 1, 1]
      ],
      [
        [1, -1, -1],
        [1, 1, 1]
      ]),
      [new StickStyle(
        [
          [0, -1, -1],
          [0, 1],
          [1, 1]
        ],
        null,
         [
          [0, 1, -1],
          [-1, 0, 1]
        ],
        [
          [1, -1, -1],
          [1, 1, 1]
        ]),
        new StickStyle(
        [
          [1, -1, -1],
          [0, 0],
          [1, 1]
        ],
        null,
          [
          [1, 1, -1],
          [-1, 1, 1]
        ],
        [
          [1, -1, -1],
          [1, 0, 0]
        ]),
        new StickStyle(
        [
          [1, -1, -1],
          [0, 0],
          [1, 1]
        ],
        null,
          [
          [1, 0, -1],
          [-1, 1, 1]
        ],
        [
          [1, -1, -1],
          [1, 1, 0]
        ]),
        new StickStyle(
        [
          [1, -1, -1],
          [0, 1],
          [1, 0]
        ],
        null,
          [
          [1, 0, -1],
          [-1, 1, 1]
        ],
        [
          [1, -1, -1],
          [1, 0, 1]
        ]),
        new StickStyle(
        [
          [0, -1, -1],
          [1, 1],
          [1, 0]
        ],
        null,
          [
          [0, 1, -1],
          [-1, 1, 1]
        ],
        [
          [1, -1, -1],
          [1, 0, 1]
        ]),
        new StickStyle(
        [
          [0, -1, -1],
          [0, 1],
          [1, 1]
        ],
        null,
          [
          [0, 1, -1],
          [-1, 1, 1]
        ],
        [
          [1, -1, -1],
          [1, 0, 1]
        ]),
        new StickStyle(
        [
          [0, -1, -1],
          [1, 1],
          [1, 1]
        ],
        null,
          [
          [0, 1, -1],
          [-1, 0, 1]
        ],
        [
          [1, -1, -1],
          [1, 0, 1]
        ])
      ], 0, 1),
    /* Level 11 data - Triangle: Move 6 matches to get 8 triangles */
    new LevelData('הזז 6 גפרורים בכדי לקבל 8 משולשים', PuzzleType.TRIANGLE, new StickStyle(
      [
        [0, 0, 0, 0],
        [1, 0, 1, -1],
        [0, 1, 1, 0],
        [1, 0, 1, -1],
        [0, 0, 0, 0]
      ],
      null,
       [
        [0, 0, 1, 0],
        [-1, 1, 1, 0, 0],
        [0, 0, 1, 1],
        [-1, 0, 1, 0, 0]
      ],
      [
        [0, 1, 0, 0],
        [0, 0, 1, 1],
        [1, 1, 0, 0],
        [0, 0, 1, 0]
      ]),
      [new StickStyle(
        [
          [0, 0, 0, 0],
          [1, 1, 1, -1],
          [0, 0, 0, 0],
          [1, 1, 1, -1],
          [0, 0, 0, 0]
        ],
        null,
         [
          [0, 0, 1, 0],
          [-1, 1, 0, 1, 0],
          [0, 1, 0, 1],
          [-1, 0, 1, 0, 0]
        ],
        [
          [0, 1, 0, 0],
          [0, 1, 0, 1],
          [1, 0, 1, 0],
          [0, 0, 1, 0]
        ])
      ], 0, 1),

    /* Level 12 data - Square: Move 6 matches to get 3 squares */
    new LevelData('הזז 6 גפרורים בכדי לקבל 3 ריבועים', PuzzleType.SQUARE, new StickStyle(
      [
      [1, 1, 1, 0, 0],
      [0, 1, 1, 0, 0],
      [1, 1, 1, 0, 0],
      [1, 1, 1, 0, 0]
    ],
    [
      [1, 0, 1, 1, 0, 0],
      [1, 1, 1, 1, 0, 0],
      [1, 1, 1, 1, 0, 0]
    ]),
    [new StickStyle(
      [
        [1, 1, 1, 0, 0],
        [0, 1, 0, 1, 1],
        [0, 1, 0, 0, 0],
        [1, 1, 1, 1, 1]
      ],
      [
        [1, 0, 0, 1, 0, 0],
        [1, 1, 1, 1, 0, 1],
        [1, 0, 0, 1, 0, 1]
      ]),
      new StickStyle(
        [
        [1, 1, 1, 1, 1],
        [0, 1, 0, 0, 0],
        [0, 1, 0, 1, 1],
        [1, 1, 1, 0, 0]
      ],
      [
        [1, 0, 0, 1, 0, 1],
        [1, 1, 1, 1, 0, 1],
        [1, 0, 0, 1, 0, 0]
      ]),
      new StickStyle(
        [
        [1, 1, 1, 1, 0],
        [0, 1, 0, 1, 0],
        [0, 1, 0, 0, 0],
        [1, 1, 1, 1, 0]
      ],
      [
        [1, 0, 0, 1, 1, 0],
        [1, 1, 1, 1, 1, 0],
        [1, 0, 0, 1, 1, 0]
      ]),
      new StickStyle(
        [
        [1, 1, 1, 1, 0],
        [0, 1, 0, 0, 0],
        [0, 1, 0, 1, 0],
        [1, 1, 1, 1, 0]
      ],
      [
        [1, 0, 0, 1, 1, 0],
        [1, 1, 1, 1, 1, 0],
        [1, 0, 0, 1, 1, 0]
      ])
    ])
  ];
  gameId = 'NhInVTDrjAeUJQNbEg8M';
  startTime = new Date();
  timeInSec = 0;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private router: Router, private firestore: AngularFirestore) {}

  ngOnInit(): void {
    this.viewContainerRef = this.msLevelDirective.viewContainerRef;
    gameService.loadGame(this.gameId).then(docs => {
      try{
        this.nCurLevel = docs.scoreDoc.data().level;
        this.timeInSec = docs.scoreDoc.data().gameTimeSecond;
      }
      catch {
        this.timeInSec = 0;
        this.nCurLevel = 0;
      }
      this.createComponent(this.nCurLevel);
    });
  }

  createComponent(nIndex): void {
    let packageData: LevelData = this.arrLevels[this.nCurLevel];

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MSLevelComponent);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    (<MSLevelComponent>componentRef.instance).action('index', {data: packageData});
    (<MSLevelComponent>componentRef.instance).action('mutedState', {data: this.isMuted});
    componentRef.instance.actionToParent.subscribe(($event) => {this.actionToParent($event);});

    this.components.push(componentRef);
  }
  back(): void{
    this.router.navigateByUrl('dashboard');
  }
  getTimeDeltaInSecond(time): number{
    return (new Date().getTime() - time.getTime()) / 1000;
  }
  actionToParent = (actionData: any) => {
    if (this.isGameOver) return;

    switch (actionData[0]) {
      case 'updateMove':
        this.nMoves++;
        break;
      case 'nextLevel':
        console.log(this.nCurLevel);
        this.nCurLevel++;
        gameService.uploadScoreReport({gameTimeSecond: this.getTimeDeltaInSecond(this.startTime) + this.timeInSec, gameScore: (this.nCurLevel+1) * 100 / this.arrLevels.length, level: this.nCurLevel, isDone: this.nCurLevel == this.arrLevels.length});
        if (this.nCurLevel < this.arrLevels.length) {
          this.nMoves = 0;
          this.removeMatchStick();
          setTimeout(() => {
            this.createComponent(this.nCurLevel);
          }, 1);
        }
        else {
          this.isGameOver = true;
          this.router.navigate(['matches/final-screen']);
        }
        break;
    }
  };

  // Move back
  moveBack() {
    this.nCurLevel--;

    if (this.nCurLevel >= 0) {
      this.nMoves = 0;
      this.removeMatchStick();
      setTimeout(() => {
        this.createComponent(this.nCurLevel);
      }, 1);
    }
    else
      this.nCurLevel = 0;
  }

  // Replay the level
  replayLevel() {
    this.nMoves = 0;
    this.removeMatchStick();
    setTimeout(() => {
      this.createComponent(this.nCurLevel);
    }, 1);
  }

  // Toggle mute/unmute
  toggleMuteUnmute() {
    this.isMuted = !this.isMuted;
    (document.querySelector('.cMuteUnmute') as HTMLElement).className = 'cProgressCmn cMuteUnmute' + (this.isMuted ? ' cMuted' : ' cUnMuted');
    (<MSLevelComponent>this.components[0].instance).action('mutedState', {data: this.isMuted});
  }

  // Remove component from both view and array
  removeMatchStick() {
    this.viewContainerRef.remove(0);
    this.components.splice(0, 1);
  }
}


@Component({
  selector: 'MatchesPopupDialog',
  templateUrl: 'popup-dialog.html',
})
export class MatchesPopupDialog {
  constructor(
    public dialogRef: MatDialogRef<MatchesPopupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string, disableKeep: boolean }) { }
}