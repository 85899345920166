




<div class="cConfirmationDialog"
      [ngStyle]="{'direction': 'rtl'}">
    <h2 mat-dialog-title>{{data.msg}}</h2>


    <div class="cControl">
        <div class="cYes" [mat-dialog-close]="1" cdkFocusInitial>{{data.approval}}</div>
        <div *ngIf='data.confirm' class="cNo" (click)="onNoClick()">לא</div>
    </div>
</div>
