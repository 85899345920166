import { SigninComponent } from '../../.components/dialogs/signin/signin.component';
import { LoginService } from '../../services/login.service';
// tslint:disable: max-line-length
import {FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult} from 'firebaseui-angular';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {ErrorStateMatcher} from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { GlobalsService } from 'src/app/services/globals.service';
import * as texts from 'src/assets/languages';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { AreYouDialogComponent } from 'src/app/.components/dialogs/are-you-dialog/are-you-dialog.component';
import { title } from 'process';


export interface Node {
  name: string;
  children?: Node[];
  parent?: Node;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  showMenuButtons = false;
  @ViewChild('stepper') stepper;
  isLinear = true;
  treeControl = new NestedTreeControl<Node>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Node>();
  btnDisabled = false;
  progressBarDisabled = true;
  codeValue;
  treeData: Node[];
  activityLogo = '';
  activityName = '';
  loginType = '';
  isNameRequired = false;
  groups: number[] = [];
  groupPath: string[] = [];
  group;
  isLoading = false;
  codeFormControl = new FormControl('', [Validators.required]);
  groupFormControl = new FormControl('', [Validators.required]);
  nameControl = new FormControl('', [Validators.required]);
  nameValue = '';
  idValue;
  currUid = '';
  currName = '';
  activityType;
  dir = 'rtl';
  language;
  translation;
  codeLabel = 'הכנס קוד';
  groupLabel = 'בחר מחלקה';
  nameLabel = 'הכנס שם';
  changeDisabled = true;
  constructor(private formBuilder: FormBuilder,
              private firestore: AngularFirestore,
              private router: Router,
              private global: GlobalsService,
              private activatedRoute: ActivatedRoute,
              private auth: AngularFireAuth,
              private loginService: LoginService,
              public dialog: MatDialog) {
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('language');
    this.codeLabel = this.translation.fillCode[this.language];
    this.groupLabel = this.translation.chooseDepart[this.language];
    this.nameLabel = this.translation.fillName[this.language];
    if (this.language == null || this.dir == null){
      if (navigator.language === 'he-IL'){
        localStorage.setItem('dir', 'rtl');
        localStorage.setItem('language', 'he');
      }
      else{
        localStorage.setItem('dir', 'ltr');
        localStorage.setItem('language', 'en');
      }
    }
    this.loginService.login().then( user => {
      if (user && user.uid){
        this.currUid = user.uid;
        this.firestore.collection('users').doc(user.uid).get().toPromise().then(userDoc => {
          this.afterLogin(userDoc);
        });
      }
      else{
        const temp = localStorage.getItem('tempCode');
        if (temp != null){
          localStorage.setItem('tempCode', temp);
        }
        const dialogRef = this.dialog.open(SigninComponent, {
          width: '400px',
          data: {title: this.translation.Signin[this.language]},
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((results: FirebaseUISignInSuccessWithAuthResult) => {
          if (results !== undefined){
            this.showMenuButtons = true;
            this.currUid = results.authResult.user.uid;
            this.firestore.collection('users').doc(this.currUid).get().toPromise().then(userDoc => {
              this.afterLogin(userDoc);
            });
          }
          else{
             window.location.reload();
          }
        });
      }
    });
  }
  changeCode(): void{
    localStorage.removeItem('tempCode');
    window.location.reload();
  }
  ngOnInit(): void {
    this.groupFormControl.setErrors({error: true});
  }
  hasChild = (_: number, node: Node) => !!node.children && node.children.length > 0;
  logoutFromAccount(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.logoutTitle[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        const temp = localStorage.getItem('tempCode');
        this.loginService.logout().then(() => {
          if (temp != null){
            localStorage.setItem('tempCode', temp);
          }
          window.location.reload();
        });
      }
    });
  }
  submitCode(stepper: MatStepper, code: string): void {
    this.codeFormControl.setValue(code);
    this.btnDisabled = true;
    this.progressBarDisabled = false;
    if (code == null || code === ''){
      this.btnDisabled = false;
      this.progressBarDisabled = true;
    }
    else{
      this.codeFormControl.markAsPending();
      this.firestore.collection('activities').doc(code).get().toPromise().then(doc => {
        if (doc.exists && doc.data() != null && doc.data()['end time'] != null && doc.data()['start time'] != null && doc.data()['start time'].toDate() < new Date()){
          localStorage.setItem('tempCode', code);
          this.changeDisabled = false;
          this.activityName = doc.data().activityName;
          this.activityLogo = doc.data().activityLogo;
          this.activityType = doc.data().activityType;
          localStorage.setItem('customerId', doc.data().customerUID);
          this.firestore.collection('customers').doc(doc.data().customerUID).get().toPromise().then( doc1 => {
            if (doc1.data().language === 'he'){
              this.dir = 'rtl';
              localStorage.setItem('dir', 'rtl');
              localStorage.setItem('language', 'he');
            }
            else{
              this.dir = 'ltr';
              localStorage.setItem('dir', 'ltr');
              localStorage.setItem('language', doc1.data().language);
            }
            this.language = localStorage.getItem('language');
            this.codeLabel = this.translation.fillCode[this.language];
            this.groupLabel = this.translation.chooseDepart[this.language];
            this.nameLabel = this.translation.fillName[this.language];
          }).then( () => {
            this.firestore.collection('users').doc(this.currUid).collection('activities').doc(code).get().toPromise().then( activityDoc => {
              if (activityDoc.exists){
                this.isLoading = true;
                const promises: Promise<any>[] = [];
                this.firestore.collection('users').doc(this.currUid).update({currActivity: code}).then(() => {
                  Promise.all(promises).then( () => {
                    localStorage.removeItem('tempCode');
                    this.router.navigateByUrl('dashboard');
                  });
                });
              }
              else{
                this.loginType = doc.data().loginType;
                if (this.loginType === 'groups'){
                  const startGroup = doc.data().startGroup;
                  const endGroup = doc.data().endGroup;
                  for (let i = startGroup; i <= endGroup; i++){
                    this.groups.push(i);
                  }
                  this.groupLabel = this.translation.chooseGroup[this.language];
                }
                this.isNameRequired = doc.data().isNameRequired;
                this.codeFormControl.setErrors(null);
                this.codeValue = code;
                this.loginService.getTreeData(this.codeValue).then(treeData => {
                  this.goForward(stepper);
                  stepper.steps.first.completed = true;
                  this.codeLabel = this.translation.code[this.language] + ': ' + code;
                  this.treeData = treeData;
                  this.btnDisabled = false;
                  this.progressBarDisabled = true;
                  this.dataSource.data = this.treeData;
                });
              }
            });
          });
        }
        else{
          localStorage.removeItem('tempCode');
          this.btnDisabled = false;
          this.progressBarDisabled = true;
          this.codeFormControl.markAsTouched();
          this.codeFormControl.setErrors({invalidCode: true});
        }
      });
    }
  }
  goBack(stepper: MatStepper): void{
    stepper.previous();
  }

  goForward(stepper: MatStepper): void{
    stepper.next();
  }
  codeFocusOut(): void{
    if (!this.codeFormControl.hasError('required'))
    {
      this.codeFormControl.markAsPending();
    }
  }
  groupClicked(stepper: MatStepper, node: Node): void{
    this.groupPath = [];
    this.groupFormControl.setErrors(null);
    this.goForward(stepper);
    let groupPath = node.name;
    this.groupPath.push(node.name);
    node = node.parent;
    while (node != null){

      groupPath = node.name + '-' + groupPath;
      this.groupPath.push(node.name);
      node = node.parent;
    }
    this.groupPath = this.groupPath.reverse();
    this.groupLabel = groupPath;
  }

  submitName(stepper: MatStepper, name: string): void{
    const realname = name;
    name = name.split(/\s/).join('');
    if (name !== ''){
      this.nameValue = realname;
      this.goForward(stepper);
      this.nameLabel = this.translation.name[this.language] + ': ' + realname;
    }
    else{
      this.nameControl.setErrors({required: true});
    }
  }
  nameFocusOut(name: string): void{
    name = name.split(/\s/).join('');
    if (name === ''){
      this.nameControl.setErrors({required: true});
    }
  }
  submitGroup(group: number): void{
    this.group = group;
    this.groupLabel = this.translation.group[this.language] + ' ' + group;
  }
  submitAll(): void{
    this.isLoading = true;
    const promises: Promise<any>[] = [];

    if (this.loginType === 'groups'){
      promises.push(this.firestore.collection('users')
                                  .doc(this.currUid)
                                  .set({currActivity: this.codeValue}));
      if (this.activityType === 'course'){
        promises.push(this.firestore.collection('users')
                                    .doc(this.currUid)
                                    .collection('activities')
                                    .doc(this.codeValue)
                                    .set({startTime: new Date(), currComponent: 0, group: this.group, name: this.nameValue}));
      }
      else{
        promises.push(this.firestore.collection('users')
                                    .doc(this.currUid)
                                    .collection('activities')
                                    .doc(this.codeValue)
                                    .set({group: this.group, name: this.nameValue}));
      }
      promises.push(this.firestore.collection('activities').doc(this.codeValue)
                                  .collection('groups').doc(this.group.toString())
                                  .collection('users').doc(this.currUid)
                                  .set({}));
    }
    else if (this.loginType === 'hierarchy'){
      promises.push(this.firestore.collection('users')
                                  .doc(this.currUid)
                                  .set({currActivity: this.codeValue}));
      if (this.activityType === 'course'){
        promises.push(this.firestore.collection('users')
                                    .doc(this.currUid)
                                    .collection('activities')
                                    .doc(this.codeValue)
                                    .set({startTime: new Date(), currComponent: 0, group: this.groupPath, name: this.nameValue}));
      }
      else{
        promises.push(this.firestore.collection('users')
                                  .doc(this.currUid)
                                  .collection('activities')
                                  .doc(this.codeValue)
                                  .set({group: this.groupPath, name: this.nameValue}));
      }
      promises.push(this.firestore.collection('activities').doc(this.codeValue).get().toPromise().then( doc => {
        return this.submitUserInStructure(doc, 0);
      }));
    }
    else if (this.loginType === 'solos'){
      promises.push(this.firestore.collection('users')
                                  .doc(this.currUid)
                                  .set({currActivity: this.codeValue}));
      if (this.activityType === 'course'){
        promises.push(this.firestore.collection('users')
                                    .doc(this.currUid)
                                    .collection('activities')
                                    .doc(this.codeValue)
                                    .set({startTime: new Date(), currComponent: 0, group: 'solo', name: this.nameValue}));
      }
      else{
        promises.push(this.firestore.collection('users')
                                  .doc(this.currUid)
                                  .collection('activities')
                                  .doc(this.codeValue)
                                  .set({group: 'solo', name: this.nameValue}));
      }
      promises.push(this.firestore.collection('activities').doc(this.codeValue)
                                  .collection('users').doc(this.currUid)
                                  .set({}));
    }

    Promise.all(promises).then( () => {
      this.firestore.collection('customers')
                  .doc(localStorage.getItem('customerId'))
                  .collection('usersStatistics')
                  .add({userId: this.currUid, activityId: this.codeValue, dateJoined: new Date()});
      localStorage.removeItem('tempCode');
      this.router.navigateByUrl('dashboard');
    });
  }
  submitUserInStructure(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>, currLevel: number): Promise<any>{
    if (currLevel === 0){
      return doc.ref.collection('structure').doc(this.groupPath[currLevel]).get().then( doc1 => {
        if (!doc1.exists){
          return doc1.ref.set({}).then(() => {
            if (currLevel === this.groupPath.length - 1){
              return doc1.ref.collection('users').doc(this.currUid).set({});
            }
            this.submitUserInStructure(doc1, currLevel + 1);
          });
        }
        else{
          if (currLevel === this.groupPath.length - 1){
            return doc1.ref.collection('users').doc(this.currUid).set({});
          }
          return this.submitUserInStructure(doc1, currLevel + 1);
        }
      });
    }
    else {
      return doc.ref.collection('children').doc(this.groupPath[currLevel]).get().then( doc1 => {
        if (!doc1.exists){
          return doc1.ref.set({}).then(() => {
            if (currLevel === this.groupPath.length - 1){
              return doc1.ref.collection('users').doc(this.currUid).set({});
            }
            return this.submitUserInStructure(doc1, currLevel + 1);
          });
        }
        else{
          if (currLevel === this.groupPath.length - 1){
            return doc1.ref.collection('users').doc(this.currUid).set({});
          }
          return this.submitUserInStructure(doc1, currLevel + 1);
        }
      });
    }
  }
  afterLogin(userDoc): void{
    if (userDoc.exists && userDoc.data() != null && userDoc.data().currActivity != null){
      if (localStorage.getItem('tempCode') != null && localStorage.getItem('tempCode') !== userDoc.data().currActivity){
        const dialogRef = this.dialog.open(AreYouDialogComponent, {
          width: '400px',
          disableClose: true,
          data: { title: this.translation.switchActivityTitle[this.language],
                  content: this.translation.switchActivityContent[this.language],
                  ok: this.translation.switchActivityOk[this.language],
                  cancel: this.translation.switchActivityCancel[this.language]
                }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === true){
            this.firestore.collection('users').doc(this.currUid).set({}).then(() => {
              this.submitCode(this.stepper, localStorage.getItem('tempCode'));
            });
          }
          else{
            this.submitCode(this.stepper, userDoc.data().currActivity);
            localStorage.removeItem('tempCode');
          }
        });
      }
      else{
        this.submitCode(this.stepper, userDoc.data().currActivity);
        localStorage.removeItem('tempCode');
      }
    }
    else if (localStorage.getItem('tempCode') != null){
      this.submitCode(this.stepper, localStorage.getItem('tempCode'));
    }
  }

}
