import { Router } from '@angular/router';
import { AfterContentInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { QuestionData } from './question.data';
import * as gameService from '../../../assets/environments/service.js';
import { AnyARecord } from 'dns';

declare var _GameRef: any;
declare var _QuizRef: any;
declare var correctAns: any;
declare var wrongAns: any;
declare var window: any;

declare var document: any;
interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'tweenjs', src: 'assets/js/tweenjs.min.js' },
  { name: 'pixi', src: 'assets/js/pixi.min.js' },
  { name: 'main', src: 'assets/js/main.js' }
];

/* export interface TrueFalseQn{
  index: number;
  correct: boolean;
} */

@Component({
  selector: 'app-true-false',
  templateUrl: './true-false.component.html',
  styleUrls: ['./true-false.component.scss']
})
export class TrueFalseComponent implements OnInit, AfterContentInit {

  ngAfterContentInit(): void {
    this.getDataFromLayout().then( () => {
      console.log('lets begin!');
    });
  }

  audGameBg: any = new Audio('assets/audios/GamePlay.mp3');
  // audApplause: any = new Audio('assets/audios/applause.mp3');
  // audFeedbackIncorrect: any = new Audio('assets/audios/feedback-incorrect.mp3');
  audCorrect: any = new Audio('assets/audios/correct.mp3');
  audWrong: any = new Audio('assets/audios/wrong.mp3');

  selectedOption: any = null;
  isGameOver: boolean = false;
  answerMultipleVal: number = 10;
  textDirecton: string = 'ltr'; // 'ltr' or 'rtl'

  bgAnimationIndex: number = 0;
  bgAnimationsIds: string[] = ['#iBgAnimation'/* , '#iBgAnimation2' */];
  bgAnimationClass: string[] = ['cCircleRipple', 'cSqareRipple', 'cDiamondRipple'];

  countDownTime: number = 5; // Time in seconds
  countDownCounter: number = 0;
  countDownSpeed: number = 5; // 1 to 5
  counterVal: number = 0;
  intervalRef: any;
  starts: number[][] = [];
  maxQnInRow: number = 5;
  currentRowOfStar: number = 0;
  currentQnIndex: number = 0;
  isFirstTime: boolean = true;
  isQnHidden: boolean = false;
  currentGameID = 'mhPqktjWFCLNEfqFhOhO';
  numOfQuestions:number = 0;
  correctCount: number= 0;
  numOfWrong:number = 0;
  arrQuestions : any []= [];
  arrAnswers : any []=[];
  arrQnAn : any[] = [];
  nScore = 0;
  arrDetailedReport: any[] = [];

  private scripts: any = {};

  private getDataFromLayout(): Promise<any>{
    return gameService.loadGame(this.currentGameID).then(docs => {
      const scoreDoc = docs.scoreDoc;
      const ConfigDoc = docs.configurationDoc;
    /*   console.log('scoreDoc',scoreDoc); */
       if (false) {
      /*   if (false) { */
          /*   this.nElapsedTime = scoreDoc.data().gameTimeSecond.toString(); */
          this.arrAnswers  = scoreDoc.data().Answers;
          let counter = 0;

          ConfigDoc.data().configurations.answers.forEach((element,index) => {
            const localVar = {index: index, answer: element };
            counter++;
            this.arrAnswers.push(localVar);
          });

          counter = 0;
          ConfigDoc.data().configurations.questions.forEach((element,index) => {
            const localVar = {index: index, question: element };
            counter++;
            this.arrQuestions.push(localVar);
          });
          /*   this.nCurQnIndex = scoreDoc.data().QuestionsIndex; */
          this.nScore = this.arrQuestions.length - scoreDoc.data().Answers.length;
          this.correctCount = this.nScore - scoreDoc.data().wrongAnswers;
          this.numOfWrong = scoreDoc.data().wrongAnswers;

          const nLen: number = this.arrAnswers.length;
          for (let i = 0; i < nLen; i++) {
            const QnAn = {question : this.arrQuestions[i].question, answer: this.arrAnswers[i].answer};
            this.arrQnAn.push(QnAn);
          }
          this.numOfQuestions = this.arrQuestions.length;

          return true;
        } else {
          let counter = 0;
          ConfigDoc.data().configurations.answers.forEach((element,index) => {
            const localVar = {index: index, answer: element };
            counter++;
            this.arrAnswers.push(localVar);
          });

          counter = 0;
          ConfigDoc.data().configurations.questions.forEach((element,index) => {
            const localVar = {index: index, question: element };
            counter++;
            this.arrQuestions.push(localVar);
          });

          const nLen: number = this.arrAnswers.length;
          for (let i = 0; i < nLen; i++) {
            const QnAn = {question : this.arrQuestions[i].question, answer: this.arrAnswers[i].answer};
            this.arrQnAn.push(QnAn);
          }
          this.numOfQuestions = this.arrQuestions.length;
          return true;
        }
      });
    }

  getCurrentQuestion(): any {
    if (!this.arrQuestions || this.arrQuestions.length == 0) {
      return;
    }

    let res = this.arrQuestions[this.currentQnIndex].question;
    return res;
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    this.unloadCanvas();
    this.unloadButton();
    this.unloadScripts();

    if (_GameRef) {
      this.isGameOver = true;
      _GameRef.endGame();
    }
  }

  private unloadScripts(): void {
    this.unloadScript('assets/js/pixi.min.js');
    this.unloadScript('assets/js/tweenjs.min.js');
    this.unloadScript('assets/js/main.js');
  }

  private unloadCanvas(): void {
    let canvases = document.getElementsByTagName('canvas');
    if (canvases && canvases[0]) {
        canvases[0].remove();
    }
  }

  private unloadButton(): void {
    let btns = document.getElementsByTagName('button');
    if (btns && btns[0]) {
      btns[0].remove();
    }
  }

  constructor(private _cdr: ChangeDetectorRef, private router: Router) {
    // this.loadScript('assets/js/tweenjs.min.js');
    // this.loadScript('assets/js/pixi.min.js');
    // this.loadScript('assets/js/main.js');

    this.audGameBg.load();
    this.audCorrect.load();
    this.audWrong.load();

    this.loadDynamicScript();
    this.load('tweenjs', 'pixi', 'main').then(data => {
      console.log('loaded js');
      dispatchEvent(new Event('load'));
    }).catch(error => console.log(error));

    /* setTimeout(() => {
      dispatchEvent(new Event('load'));
    }, 500); */
  }

  loadDynamicScript()  {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = this.scripts[name].src;
        script.src = this.scripts[name].src;
        script.async = false;
        if (script.readyState) {  //IE
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  //Others
            script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});

        let body = <HTMLDivElement>document.body;
        body.appendChild(script);
        // document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

  ngOnInit() {
    _QuizRef = this;
  }

  /* loadScript(path): void {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.id = path;
    script.src = path;
    script.async = false;
    body.appendChild(script);
  } */

  unloadScript(path): void {
    let el = document.getElementById(path);
    if (el) {
      el.remove();
    }
  }

  changeStatus(): void {
    setTimeout(() => {
      this._cdr.detectChanges();
    }, 1000);
  }

  // Start game
  startGame(p_event) {
    (p_event.currentTarget.parentNode as HTMLElement).style.display = 'none';
    this.init();
    // this.audGameBg.loop = true;
    // this.audGameBg.volume = 0.4;
    // this.audGameBg.play();
  }

  // Initialize the game
  init() {
    this.correctCount = 0;
    this.isGameOver = false;
    this.currentQnIndex = 0;
    this.currentRowOfStar = 0;
    this.starts[this.currentRowOfStar] = [];
    this.isFirstTime = true;


    setTimeout(() => {
      (document.querySelector('.cQuestionHolder') as HTMLElement).style.opacity = '1';
      (document.querySelector('.cQuestionHolder') as HTMLElement).style.direction = this.textDirecton;

      document.querySelectorAll('.mat-radio-outer-circle').forEach((item) => {
        (item as HTMLElement).style.borderColor = '#ffffff';
      });
    }, 300);
    document.querySelectorAll('.mat-radio-container').forEach((item) => {
      (item as HTMLElement).style.marginLeft = '10px';
      (item as HTMLElement).style.marginRight = '10px';
    });
    document.querySelectorAll('.mat-radio-label-content').forEach((item) => {
      (item as HTMLElement).style.paddingLeft = '0';
    });
    document.querySelectorAll('.mat-radio-outer-circle').forEach((item) => {
      (item as HTMLElement).style.borderColor = '#6903a1';
    });
    this.changeStatus();
  }

  hideQn() {
    this.isQnHidden = true;
    setTimeout(() => {

      setTimeout(() => {
        let cQnAnim = document.querySelector('.cQnAnim') as HTMLElement;
        if (cQnAnim) {
          cQnAnim.style.display = 'none';
        }

        let cQuestion = document.querySelector('.cQuestion') as HTMLElement;
        if (cQuestion) {
          cQuestion.style.display = 'none';
        }

        document.querySelectorAll('mat-radio-button').forEach((item) => {
          (item as HTMLElement).style.display = 'none';
          (item as HTMLElement).style.pointerEvents = 'none';
        });
      }, 100);
    }, 50);
  }

  showQn() {
    this.isQnHidden = false;
    setTimeout(() => {
      this.nextQn();
    }, 300);
  }

  nextQn() {
    if (!this.isGameOver) {
      this.selectedOption = null;
      if (this.currentQnIndex < this.arrQuestions.length - 1) {
         let cGameContainer = document.querySelector('.cGameContainer') as HTMLElement;
         if (cGameContainer){
                     cGameContainer.style.pointerEvents = 'auto'}
        let cQnAnim = document.querySelector('.cQnAnim') as HTMLElement;
        if (cQnAnim){
                     cQnAnim.style.display = 'block'
                     cQnAnim.className = 'cQnAnim zoomInAnim';
                    }
        let cQuestion = document.querySelector('.cQuestion') as HTMLElement;
        if (cQuestion){
                     cQuestion.style.display = 'block' }
        setTimeout(() => {
          document.querySelectorAll('mat-radio-button').forEach((item) => {
            (item as HTMLElement).style.display = 'block';
            (item as HTMLElement).style.pointerEvents = 'auto';
          });
        }, 300);
        if (this.isFirstTime)
          this.isFirstTime = false;
        else
          this.currentQnIndex++;
      }
      else {
        (document.querySelector('.cGameContainer') as HTMLElement).style.pointerEvents = 'none';
        this.isGameOver = true;
        (document.querySelector('.cQuestionHolder') as HTMLElement).style.opacity = '0';

        this.destroy();

        _GameRef.update();

        this.router.navigateByUrl('/dashboard');
      }
    }
  }

  playAudio(audio: any) {
    audio.pause();
    audio.currentTime = 0;
    audio.play();
  }

  updateStar() {
    let row: number = Math.floor(this.currentQnIndex / this.maxQnInRow);
    if (row != this.currentRowOfStar) {
      this.currentRowOfStar = row;
      this.starts[this.currentRowOfStar] = [];
    }

    this.starts[this.currentRowOfStar].push(1);
  }

  onCorrectAnswer() {
    this.correctCount++;
    (document.querySelector('#iCorrect') as HTMLElement).style.display = 'block';
    correctAns();
  }

  onWrongAnswer() {
    this.numOfWrong++;
    (document.querySelector('#iWrong') as HTMLElement).style.display = 'block';
    wrongAns();
  }

  moveNext() {
    setTimeout(() => {
      let cQnAnim = document.querySelector('.cQnAnim') as HTMLElement;
      if (cQnAnim) {
        cQnAnim.className = 'cQnAnim zoomOutAnim';
      }

      setTimeout(() => {
        let cQnAnim = document.querySelector('.cQnAnim') as HTMLElement;
        if (cQnAnim) {
          cQnAnim.style.display = 'none';
        }

        let cQuestion = document.querySelector('.cQuestion') as HTMLElement;
        if (cQuestion) {
          cQuestion.style.display = 'none';
        }

        let allMatRadioBtns = document.querySelectorAll('mat-radio-button');
        if (allMatRadioBtns) {
          allMatRadioBtns.forEach((item) => {
            (item as HTMLElement).style.display = 'none';
            (item as HTMLElement).style.pointerEvents = 'none';
          });
        }

        (document.querySelector('#iCorrect') as HTMLElement).style.display = 'none';
        (document.querySelector('#iWrong') as HTMLElement).style.display = 'none';

        setTimeout(() => {
          this.nextQn();
        }, 100);
      }, 400);
    }, 100);
  }


  checkAnswer(event) {
    document.querySelectorAll('mat-radio-button').forEach((item) => {
      (item as HTMLElement).style.pointerEvents = 'none';
    });

    const idx = this.currentQnIndex;
    if (event.value == this.arrQnAn[idx].answer) {
      this.onCorrectAnswer();
      this.arrDetailedReport[idx] = {question: this.arrQuestions[idx].question, userAnswer: event.value, isCorrectAnswer: true};

      // gameService.uploadDetailedReport(idx, {question: this.arrQuestions[idx].question, userAnswer: event.value, isCorrectAnswer: true});
    } else {
      this.onWrongAnswer();
      this.arrDetailedReport[idx] = {question: this.arrQuestions[idx].question, userAnswer: event.value, isCorrectAnswer: false};

      // gameService.uploadDetailedReport(idx, {question: this.arrQuestions[idx].question, userAnswer: event.value, isCorrectAnswer: false});
    }
  }

  updateScore(score: number): void {
    if (this.currentQnIndex === (this.arrQuestions.length - 1)) {
      gameService.getScore().then( prevScore => {
          // the first time playing
          if (!prevScore) {
            prevScore = -1;
          }

          // the next times
         if (score > prevScore) {
          gameService.uploadScoreReport({ gameScore: score, wrongAnswers: this.numOfWrong, isDone: true});
          this.arrDetailedReport.forEach((item, index) => {
            gameService.uploadDetailedReport(index, {question: item.question, userAnswer: item.userAnswer, isCorrectAnswer: item.isCorrectAnswer});
          })
         }
      });
    }
  }

  startCountDown() {
    this.countDownCounter = 0;
    let counterWidth: number = (this.counterVal = Math.round(
      (this.countDownTime - this.countDownCounter) * ((1000 * this.countDownSpeed) / 200)));

    clearInterval(this.intervalRef);
    this.intervalRef = setInterval(() => {
      this.countDownCounter += 200 / (1000 * this.countDownSpeed);

      if (this.countDownCounter >= this.countDownTime) {
        clearInterval(this.intervalRef);
        this.timeUp();
      } else {
        this.counterVal = Math.round((this.countDownTime - this.countDownCounter) * ((1000 * this.countDownSpeed) / 200));
        if (this.counterVal >= 0) {
          (document.querySelector('.cCountDown') as HTMLElement).style.width = (100 * this.counterVal) / counterWidth + '%';
        }
      }
    }, 200 / this.countDownSpeed);
  }

  timeUp() {
    this.onWrongAnswer();
  }
  
  back() {
    window.location = "/";
  }
}
