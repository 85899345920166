// tslint:disable: max-line-length
import { GlobalsService } from 'src/app/services/globals.service';
import { Component } from '@angular/core';
import { Route, Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { AreYouDialogComponent } from './.components/dialogs/are-you-dialog/are-you-dialog.component';
import * as texts from 'src/assets/languages';
import { last, pluck, takeWhile, takeLast} from 'rxjs/operators';
import { LoginService } from './services/login.service';
import { clear } from 'console';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Yooz';
  authState: any = null;
  navigationExtras: NavigationExtras;
  dir = 'rtl';
  language;
  translation;
  params;
  isDialogOpen = false;
  constructor(private router: Router, private loginService: LoginService,  private firestore: AngularFirestore, private activatedRoute: ActivatedRoute, public dialog: MatDialog){
    if (localStorage.getItem('dir') == null || localStorage.getItem('language') == null){
      if (navigator.language === 'he-IL'){
        localStorage.setItem('dir', 'rtl');
        localStorage.setItem('language', 'he');
      }
      else{
        localStorage.setItem('dir', 'ltr');
        localStorage.setItem('language', 'en');
      }
    }
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
    const params = this.activatedRoute.snapshot.queryParams;
    this.params = params;
    this.loginService.login().then( user => {
      if (user && user.uid){
        this.firestore.collection('users').doc(user.uid).get().toPromise().then(userDoc => {
          if (userDoc.exists && userDoc.data().currActivity != null){
            this.firestore.collection('activities').doc(userDoc.data().currActivity).get().toPromise().then(doc => {
              if (localStorage.getItem('customerId') === null){
                localStorage.setItem('customerId', doc.data().customerUID);
              }
              this.firestore.collection('customers').doc(doc.data().customerUID).get().toPromise().then( doc1 => {
                const tempLang = localStorage.getItem('language');
                if (doc1.data().language === 'he'){
                  this.dir = 'rtl';
                  localStorage.setItem('dir', 'rtl');
                  localStorage.setItem('language', 'he');
                }
                else{
                  this.dir = 'ltr';
                  localStorage.setItem('dir', 'ltr');
                  localStorage.setItem('language', doc1.data().language);
                }
                if(tempLang !== doc1.data().language){
                  window.location.reload();
                }
              });
              if (doc.exists){
                const tempurl = router.url.split('?')[0];
                if (params.activityCode != null && params.activityCode !== userDoc.data().currActivity){
                  this.isDialogOpen = true;
                  const dialogRef = this.dialog.open(AreYouDialogComponent, {
                    width: '400px',
                    data: { title: this.translation.leaveActivityTitle[this.language],
                            content: this.translation.leaveActivityContent[this.language],
                            ok: this.translation.logout[this.language]
                          }
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result === true){
                      this.firestore.collection('users').doc(user.uid).set({}).then(() => {
                        this.notFinished();
                      });
                    }
                    else if(tempurl === '' || tempurl === '/' ){
                      this.router.navigateByUrl('dashboard').then(() => {
                        window.location.reload();
                      });
                    }
                  });
                }
                else if (doc.data().activityType === 'games' && doc.data()['end time'].toDate() < new Date()){
                  this.router.navigateByUrl('leaderboard');
                }
                else if (doc.data().activityType === 'course' && doc.data()['end time'].toDate() < new Date()){
                  this.router.navigateByUrl('dashboard/course-end');
                }
                else if (localStorage.getItem('tempCode') != null){
                  this.router.navigateByUrl('login');
                }
                else if (tempurl === '' || tempurl === '/' ){
                  this.router.navigateByUrl('dashboard');
                }
              }
              else{
                this.notFinished();
              }
            });
          }
          else{
            this.notFinished();
          }
        });
      }
      else{
        this.notFinished();
      }
    });
  }
  notFinished(): void{
    if (this.params.activityCode != null){
      localStorage.setItem('tempCode', this.params.activityCode);
    }
    this.router.navigate(['login']);
  }
}
