// Copyright © Marvsoft LLP | All Rights Reserved

import { CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LevelData } from '../matches-puzzle/level-data';
import { MatchesPopupDialog } from '../matches-puzzle/matches-puzzle.component';
import { PuzzleType } from '../matches-puzzle/puzzle-type';
import { StickStyle } from '../matches-puzzle/stick-style';

@Component({
  selector: 'app-ms-level',
  templateUrl: './ms-level.component.html',
  styleUrls: ['./ms-level.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MSLevelComponent {
  @Output("actionToParent") actionToParent: EventEmitter<any> = new EventEmitter();

  levelData: LevelData;
  isMuted: boolean = false;
  totalRows: number;
  totalCols: number;
  nLength: number;
  square: string;
  triangle: string;
  numeric: string;
  nZIndex: number = 1;
  board: StickStyle;
  additionalSticks: number[];
  additionalSticksYPos: number = 500;
  mStickTarget: HTMLElement;
  mDraggedStick: HTMLElement;
  nToleration: number = 10;

  audCorrect: any = new Audio('assets/audios/clapping-hands.mp3');

  constructor(public dialog: MatDialog) {
    this.square = PuzzleType.SQUARE;
    this.triangle = PuzzleType.TRIANGLE;
    this.numeric = PuzzleType.NUMERIC;
  }

  // Clone game board to keep tracking of match stick movement
  cloneBoard() {
    this.board = new StickStyle();

    if(this.levelData.board.H) {
      let arrData: number[][] = [];
      
      for (let i = 0; i < this.levelData.board.H.length; i++) {
        const element = this.levelData.board.H[i];
        arrData[i] = [];
        
        for (let j = 0; j < element.length; j++) {
          arrData[i][j] = element[j];
        }
      }

      this.board.H = arrData;
    }

    if(this.levelData.board.V) {
      let arrData: number[][] = [];

      for (let i = 0; i < this.levelData.board.V.length; i++) {
        const element = this.levelData.board.V[i];
        arrData[i] = [];
        
        for (let j = 0; j < element.length; j++) {
          arrData[i][j] = element[j];
        }
      }

      this.board.V = arrData;
    }
    if(this.levelData.board.BS) {
      let arrData: number[][] = [];

      for (let i = 0; i < this.levelData.board.BS.length; i++) {
        const element = this.levelData.board.BS[i];
        arrData[i] = [];
        
        for (let j = 0; j < element.length; j++) {
          arrData[i][j] = element[j];
        }
      }

      this.board.BS = arrData;
    }
    if(this.levelData.board.FS) {
      let arrData: number[][] = [];

      for (let i = 0; i < this.levelData.board.FS.length; i++) {
        const element = this.levelData.board.FS[i];
        arrData[i] = [];
        
        for (let j = 0; j < element.length; j++) {
          arrData[i][j] = element[j];
        }
      }

      this.board.FS = arrData;
    }

    if(this.levelData.additionalSticks > 0) {
      this.additionalSticks = [];

      for (let i = 0; i < this.levelData.additionalSticks; i++) {
        this.additionalSticks[i] = 1;
      }
    }
  }

  // Action call from parent game controller
  action = (actionType: string, actionVal: any) => {
    switch (actionType) {
      case "index":
        this.levelData = actionVal.data;
        this.cloneBoard();
        this.drawStick();
        break;

      case "mutedState":
        this.isMuted = actionVal.data;
        this.audCorrect.muted = this.isMuted;
        break;
    }
  }

  // Draw match sticks - board
  drawStick() {
    this.getTotalRowColCount();

    let MStickHolder: HTMLElement = document.querySelector('.cMStickHolder') as HTMLElement;
    let nWidth: number = MStickHolder.clientWidth / this.totalCols;
    let nHeight: number = (MStickHolder.clientHeight - (document.querySelector('.cSheep') as HTMLElement).clientHeight - 80) / this.totalRows;
    this.nLength = nWidth;

    if(nWidth > nHeight) {
      this.nLength = nHeight;
    }
    MStickHolder.style.width = this.nLength * this.totalCols + 'px';
    this.additionalSticksYPos = document.querySelector('.cMStickHolder').getBoundingClientRect().height - this.nLength;
  }

  // Get total rows and columns
  getTotalRowColCount() {
    this.totalCols = 0;
    this.totalRows = 0;

    switch (this.levelData.type) {
      case PuzzleType.SQUARE:
        this.totalCols = this.levelData.board.H[0].length;
        this.totalRows = this.levelData.board.V.length;
        break;

      case PuzzleType.TRIANGLE:
        this.totalCols = this.levelData.board.H[0].length;// > this.levelData.board.FS[0].length ? this.levelData.board.BS[0].length : this.levelData.board.FS[0].length;
        this.totalRows = this.levelData.board.BS.length > this.levelData.board.FS.length ? this.levelData.board.BS.length : this.levelData.board.FS.length;
        break;

      case PuzzleType.NUMERIC:
        this.totalCols = this.levelData.board.H[0].length;
        this.totalRows = this.levelData.board.V.length;
        break;
    }
  }

  // Action after drag ended.
  dragStarted(event: CdkDragStart) {
    let dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
    dragItem.style.zIndex = String(++this.nZIndex);
    this.mStickTarget = null;
    this.mDraggedStick = dragItem;
  }

  // On dragging match stick
  dragMoved(event: CdkDragMove) {
    let dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;

    if((dragItem.getBoundingClientRect().top + dragItem.getBoundingClientRect().height) >= document.querySelector('.cGameContainer').getBoundingClientRect().height - this.nLength) {
      switch (dragItem.attributes['StickStyle'].value) {
        case 'H':
          dragItem.style.transform += ' rotate(-90deg)';
          break;
      
        case 'BS':
          dragItem.style.transform += ' rotate(-30deg)';
          break;
      
        case 'FS':
          dragItem.style.transform += ' rotate(-30deg)';
          break;
      }
    }

    this.checkCollision(dragItem, event.pointerPosition);
  }

  // Action after drag ended.
  dragEnded(event: CdkDragEnd) {
    let dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
    this.updateBoard(dragItem);

    if(this.mStickTarget) {
      event.source._dragRef.setFreeDragPosition({ x: 0, y: 0 });
      this.updateMStickNewPos();
    }

    this.checkForCompletion();
  }

  // Check collision
  checkCollision(item: HTMLElement = null, itemPos: any = null) {
    this.mStickTarget = null;
    let mSticks: NodeList = document.querySelectorAll('.cBoardBg .cMatchStick');

    for (let i = 0; i < mSticks.length; i++) {
      let mStick: HTMLElement = mSticks[i] as HTMLElement;
      let row: number = mStick.attributes['row'].value;
      let col: number = mStick.attributes['col'].value;
      let stickStyle: string = mStick.attributes['stickStyle'].value;
      
      if (stickStyle == 'H' && this.board.H[row][col] == 0) {
        let mStickRect = mStick.getBoundingClientRect();

        if((mStickRect.x < itemPos.x && itemPos.x < mStickRect.x + mStickRect.width) && (mStickRect.y - this.nToleration < itemPos.y && itemPos.y < mStickRect.y + mStickRect.height + this.nToleration)) {
          this.mStickTarget = mStick;
          break;
        }
      }
      else if (stickStyle == 'V' && this.board.V[row][col] == 0) {
        let mStickRect = mStick.getBoundingClientRect();

        if((mStickRect.x - this.nToleration < itemPos.x && itemPos.x < mStickRect.x + mStickRect.width + this.nToleration) && (mStickRect.y < itemPos.y && itemPos.y < mStickRect.y + mStickRect.height)) {
          this.mStickTarget = mStick;
          break;
        }
      }
      else if (stickStyle == 'BS' && this.board.BS[row][col] == 0) {
        let mStickRect = mStick.getBoundingClientRect();

        if((mStickRect.x - this.nToleration < itemPos.x && itemPos.x < mStickRect.x + mStickRect.width + this.nToleration) && (mStickRect.y < itemPos.y && itemPos.y < mStickRect.y + mStickRect.height)) {
          this.mStickTarget = mStick;
          break;
        }
      }
      else if (stickStyle == 'FS' && this.board.FS[row][col] == 0) {
        let mStickRect = mStick.getBoundingClientRect();

        if((mStickRect.x - this.nToleration < itemPos.x && itemPos.x < mStickRect.x + mStickRect.width + this.nToleration) && (mStickRect.y < itemPos.y && itemPos.y < mStickRect.y + mStickRect.height)) {
          this.mStickTarget = mStick;
          break;
        }
      }
    }
  }

  // Update position on board
  updateBoard(item: HTMLElement) {
    let stickStyle: string = item.attributes['stickStyle'].value;
    let row: number = parseInt(item.attributes['row'].value);
    let col: number = parseInt(item.attributes['col'].value);

    if(row != -1 && col != -1) {
      switch (stickStyle) {
        case 'H':
          this.board.H[row][col] = 0;
          break;
          
        case 'V':
          this.board.V[row][col] = 0;
          break;
  
        case 'BS':
          this.board.BS[row][col] = 0;
          break;
  
        case 'FS':
          this.board.FS[row][col] = 0;
          break;
      }
    }
  }

  // Update match stick for new position
  updateMStickNewPos() {
    this.mDraggedStick.style.left = this.mStickTarget.style.left;
    this.mDraggedStick.style.top = this.mStickTarget.style.top;
    this.mDraggedStick.style.transform = '';
    let stickStyle: string = this.mStickTarget.attributes['stickStyle'].value;
    let row: string = this.mStickTarget.attributes['row'].value;
    let col: string = this.mStickTarget.attributes['col'].value;
    
    if(stickStyle == 'V') {
      this.board.V[parseInt(row)][parseInt(col)] = 1;
      this.mDraggedStick.style.transform = 'rotate(-90deg)';
    }
    else if(stickStyle == 'H') {
      this.board.H[parseInt(row)][parseInt(col)] = 1;
    }
    else if(stickStyle == 'BS') {
      this.board.BS[parseInt(row)][parseInt(col)] = 1;
      this.mDraggedStick.style.transform = 'rotate(60deg) scaleX(-1)';
    }
    else if(stickStyle == 'FS') {
      this.board.FS[parseInt(row)][parseInt(col)] = 1;
      this.mDraggedStick.style.transform = 'rotate(-60deg)';
    }

    this.mDraggedStick.attributes['row'].value = row;
    this.mDraggedStick.attributes['col'].value = col;
    this.mDraggedStick.attributes['stickStyle'].value = stickStyle;
  }

  // Check for level completion
  checkForCompletion() {
    let isCompleted: boolean = true;
    let arrAnswers: StickStyle[] = this.levelData.answer;

    for (let a = 0; a < arrAnswers.length; a++) {
      const arrAnswer = arrAnswers[a];
      isCompleted = true;

      if(isCompleted && this.board.H) {
          for (let i = 0; i < this.board.H.length; i++) {
            const element = this.board.H[i];
            
            for (let j = 0; j < element.length; j++) {
              const element2 = element[j];
              
              if(element2 !== arrAnswer.H[i][j])
                isCompleted = false;
      
              if(!isCompleted)
                break;
            }
      
            if(!isCompleted)
                break;
          }
      }

      if(isCompleted && this.board.V) {
          for (let i = 0; i < this.board.V.length; i++) {
            const element = this.board.V[i];
            
            for (let j = 0; j < element.length; j++) {
              const element2 = element[j];
              
              if(element2 !== arrAnswer.V[i][j])
                isCompleted = false;
      
              if(!isCompleted)
                break;
            }
      
            if(!isCompleted)
                break;
          }
      }
  
      if(isCompleted && this.board.BS) {
          for (let i = 0; i < this.board.BS.length; i++) {
            const element = this.board.BS[i];
            
            for (let j = 0; j < element.length; j++) {
              const element2 = element[j];
              
              if(element2 !== arrAnswer.BS[i][j])
                isCompleted = false;
      
              if(!isCompleted)
                break;
            }
      
            if(!isCompleted)
                break;
          }
      }
  
      if(isCompleted && this.board.FS) {
          for (let i = 0; i < this.board.FS.length; i++) {
            const element = this.board.FS[i];
            
            for (let j = 0; j < element.length; j++) {
              const element2 = element[j];
              
              if(element2 !== arrAnswer.FS[i][j])
                isCompleted = false;
      
              if(!isCompleted)
                break;
            }
      
            if(!isCompleted)
                break;
          }
      }

      if(isCompleted)
      break;
    }

    this.actionToParent.emit(['updateMove', []]);

    if(isCompleted) {
      setTimeout(() => {
        this.levelCompleted();
      }, 300);
    }
  }
  
  // Update game for completed level
  levelCompleted() {
    this.audCorrect.pause();
    this.audCorrect.currentTime = 1;
    this.audCorrect.play();
    this.actionToParent.emit(['nextLevel', []]);
    // const dialogRef = this.dialog.open(MatchesPopupDialog, {
    //   width: '400px',
    //   height: '200px',
    //   data: {message: 'כל הכבוד! השלמת את השלב הזה.'}
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   this.audCorrect.pause();
    //   this.actionToParent.emit(['nextLevel', []]);
    // });
  }

}
