import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FactoryService {
  public packages = [];
  public numOfQuestions = 0;
  public packagCounter;
  public numOfCorrect = 0;
  public numOfWrong = 0;
  public currComponentIndex = 0;
  constructor() { }
}
