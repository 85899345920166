// Copyright © Marvsoft LLP | All Rights Reserved

import { StickStyle } from './stick-style';

export class LevelData {
    instruction: string;
    type: string;
    board: StickStyle;
    answer: StickStyle[];
    supplementH: number;
    supplementS: number;
    additionalSticks: number;

    constructor(instruction: string, type: string, board: StickStyle, answer: StickStyle[], supplementH: number = 0, supplementS: number = 0, additionalSticks: number = 0) {
        this.instruction = instruction;
        this.type = type;
        this.board = board;
        this.answer = answer;
        this.supplementH = supplementH;
        this.supplementS = supplementS;
        this.additionalSticks = additionalSticks;
    }
}