
<button id='back' (click)="back()" class='back-btn'>
    <mat-icon>arrow_back</mat-icon>
    חזור
</button>
<div class='header-purple'> 
    <!-- <div>
        <img src="assets/images/logo-white.png" class="yooz-logo">
    </div>  -->
    <div class="header-purple-wrapper" >
        <span [ngClass]="{'c10': (activityName.length < 20)}" class="text-header">{{activityName}}</span>
         <!--    ,'c20': (activityName.length > 20), 'c30': (activityName.length > 30), 'c40': (activityName.length > 40), 'c50': (activityName.length > 50)}" class="text-header">{{activityName}}</span> -->

        <div class="header-purple-btn-wrapper" *ngIf="showMenuButtons">
            <button mat-icon-button class="header-purple-buttons-icon" title="החלף פעילות" (click)="logoutFromActivity()">
                <mat-icon>autorenew</mat-icon>
            </button>
            <button mat-icon-button class="header-purple-buttons" title="התנתק" (click)="logoutFromAccount()">
                <mat-icon svgIcon="icon_logout" ></mat-icon>
            </button>
        </div>
        <div style="margin-left: 20px; margin-top: 15px; margin-right: auto;">
            <!-- <span style="font-size: 36px; font-weight: bold; color: #fff;">LOGO</span> -->
            <img src='{{logoImg}}' class="yooz-logo">
        </div>
    </div>

</div>
