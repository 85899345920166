<button  id='check' mat-mini-fab color="primary" [matMenuTriggerFor]="menu" class='back-btn' style="display: none;">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu [dir]="dir" #menu="matMenu">
    <button mat-menu-item (click)="logoutFromActivity()">
        <mat-icon >autorenew</mat-icon>
        <span>{{translation.switchActivity[language]}}</span>
    </button>
    <button mat-menu-item (click)="logoutFromAccount()">
        <mat-icon >login</mat-icon>
        <span>{{translation.logoutFromAccount[language]}}</span>
    </button>
  </mat-menu>
  <div class="loading-spin-wrapper">
    <mat-spinner class="loadingSpin" *ngIf="loading"></mat-spinner>
</div>
<img (click)="moveToLeaderBoard()" class="leaderboardButton" src="assets/images/score-btn.png" *ngIf="haveLeaderBoard"/>

<div [dir]="dir" *ngIf='!loading' style="margin-top:-5vh;height:100% ;width: 100%; margin-left: auto; margin-right: auto; overflow-y: scroll;overflow-x: hidden;">
    <mat-card class="contentCard">
        <h1 class="content-card-title" style="height:30%">{{translation.courseDoneTitle[language]}}</h1>
      <!--   <div dir="auto" class="content-card-body">{{translation.courseDoneContent[language]}}</div> -->
    </mat-card>
</div>
