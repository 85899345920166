<div [dir]="dir" class="main-container">    
    <app-header [activityName]="activityName" [activityLogo]="activityLogo"></app-header>
    <button *ngIf="!loading && !finished" mat-mini-fab color="primary" (click)="back()" class='back-btn'>
        <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="loading-spin-wrapper">
        <mat-spinner class="loadingSpin" *ngIf="loading"></mat-spinner>
    </div>    
    <div *ngIf="!loading" class="mat-list-container">
        <div class="title-wrapper">
            <button class="title-button">
                <span class="mat-list-top-title">{{translation.leaderboard[language]}}</span>
            </button>
        </div>
        <mat-list>
            <mat-list-item *ngFor="let result of results">
               <div class="group">
                    <div class="rank">
                        <div class="component-index-outside"></div>
                        <div class="componentIndex">{{result.place}}</div>
                    </div>
                    <div class="name">{{result.name}}</div>
                    <div class="score">{{result.score}}</div>
               </div>                          
            </mat-list-item>            
        </mat-list> 
    </div>
    <div class="footer-logo-wrapper">
        <img src="assets/images/shortenLogo.png">
    </div>
</div>
    