<!-- Copyright © Marvsoft LLP | All Rights Reserved -->

<div class="cInstruction">{{levelData.instruction}}</div>
<div class="cMStickHolder" >

    <!-- Match stick for board - background -->
    <div class="cBoardBg">
        <!-- Level Type - Square -->
        <div *ngIf="levelData.type == square">
            <div *ngFor="let item of this.levelData.board.H; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 != -1" stickStyle="H" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                </div>
            </div>
            <div *ngFor="let item of this.levelData.board.V; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 != -1" stickStyle="V" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength - nLength / 2), 'top.px': (i * nLength + nLength / 2), 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(-90deg)'}"></div>
                </div>
            </div>
        </div>

        <!-- Level Type - Numeric -->
        <div *ngIf="levelData.type == numeric">
            <div *ngFor="let item of this.levelData.board.H; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 == 0 || item2 == 1" stickStyle="H" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                    
                    <div *ngIf="item2 == 2" stickStyle="H" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                    <div *ngIf="item2 == 2" stickStyle="V" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength), 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(-90deg)'}"></div>

                    <div *ngIf="item2 == 3" stickStyle="H" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength - nLength * 0.2), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                    <div *ngIf="item2 == 3" stickStyle="H" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength + nLength * 0.2), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                </div>
            </div>
            <div *ngFor="let item of this.levelData.board.V; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 == 0 || item2 == 1" stickStyle="V" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength - nLength / 2), 'top.px': (i * nLength + nLength / 2), 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(-90deg)'}"></div>
                </div>
            </div>
        </div>

        <!-- Level Type - Triangle -->
        <div *ngIf="levelData.type == triangle">
            <div *ngFor="let item of this.levelData.board.H; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 != -1" stickStyle="H" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength + ((i + this.levelData.supplementH) % 2) * nLength / 2), 'top.px': (i * nLength), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                </div>
            </div>
            <div *ngFor="let item of this.levelData.board.BS; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 != -1" stickStyle="BS" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength - nLength * 0.75 + ((i + this.levelData.supplementS) % 2) * nLength / 2), 'top.px': (i * nLength + nLength * 0.5), 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(60deg) scaleX(-1)'}"></div>
                </div>
            </div>
            <div *ngFor="let item of this.levelData.board.FS; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 != -1" stickStyle="FS" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength - nLength * 0.25 + ((i + this.levelData.supplementS) % 2) * nLength / 2), 'top.px': (i * nLength + nLength * 0.5), 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(-60deg)'}"></div>
                </div>
            </div>
        </div>
    </div>

    <!-- Match stick for board -->
    <div class="cBoard">
        <!-- Level Type - Square -->
        <div *ngIf="levelData.type == square">
            <div *ngFor="let item of this.levelData.board.H; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 == 1" stickStyle="H" [attr.row]="i" [attr.col]="j" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" (cdkDragMoved)="dragMoved($event)" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                </div>
            </div>
            <div *ngFor="let item of this.levelData.board.V; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 == 1" stickStyle="V" [attr.row]="i" [attr.col]="j" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" (cdkDragMoved)="dragMoved($event)" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength - nLength / 2), 'top.px': (i * nLength + nLength / 2), 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(-90deg)'}"></div>
                </div>
            </div>
        </div>
        
        <!-- Level Type - Numeric -->
        <div *ngIf="levelData.type == numeric">
            <div *ngFor="let item of this.levelData.board.H; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 == 1" stickStyle="H" [attr.row]="i" [attr.col]="j" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" (cdkDragMoved)="dragMoved($event)" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                    
                    <div *ngIf="item2 == 2" stickStyle="H" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                    <div *ngIf="item2 == 2" stickStyle="V" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength), 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(-90deg)'}"></div>

                    <div *ngIf="item2 == 3" stickStyle="H" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength - nLength * 0.2), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                    <div *ngIf="item2 == 3" stickStyle="H" [attr.row]="i" [attr.col]="j" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength), 'top.px': (i * nLength + nLength * 0.2), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                </div>
            </div>
            <div *ngFor="let item of this.levelData.board.V; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 == 1" stickStyle="V" [attr.row]="i" [attr.col]="j" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" (cdkDragMoved)="dragMoved($event)" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength - nLength / 2), 'top.px': (i * nLength + nLength / 2), 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(-90deg)'}"></div>
                </div>
            </div>
        </div>
        
        <!-- Level Type - Triangle -->
        <div *ngIf="levelData.type == triangle">
            <div *ngFor="let item of this.levelData.board.H; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 == 1" stickStyle="H" [attr.row]="i" [attr.col]="j" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" (cdkDragMoved)="dragMoved($event)" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength + ((i + this.levelData.supplementH) % 2) * nLength / 2), 'top.px': (i * nLength), 'width.px': nLength, 'height.px': (nLength * 0.147)}"></div>
                </div>
            </div>
            <div *ngFor="let item of this.levelData.board.BS; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 == 1" stickStyle="BS" [attr.row]="i" [attr.col]="j" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" (cdkDragMoved)="dragMoved($event)" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength - nLength * 0.75 + ((i + this.levelData.supplementS) % 2) * nLength / 2), 'top.px': (i * nLength + nLength * 0.5), 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(60deg) scaleX(-1)'}"></div>
                </div>
            </div>
            <div *ngFor="let item of this.levelData.board.FS; index as i">
                <div *ngFor="let item2 of item; index as j">
                    <div *ngIf="item2 == 1" stickStyle="FS" [attr.row]="i" [attr.col]="j" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" (cdkDragMoved)="dragMoved($event)" class='cMatchStick' [ngStyle]="{'left.px': (j * nLength - nLength * 0.25 + ((i + this.levelData.supplementS) % 2) * nLength / 2), 'top.px': (i * nLength + nLength * 0.5), 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(-60deg)'}"></div>
                </div>
            </div>
        </div>

        <!-- Additional match sticks -->
        <div *ngIf="this.levelData.additionalSticks > 0">
            <div *ngFor="let item of additionalSticks; index as i">
                <div stickStyle="V" [attr.row]="-1" [attr.col]="-1" cdkDrag (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)" (cdkDragMoved)="dragMoved($event)" class='cMatchStick' [ngStyle]="{'left.px': (i * 20), 'top.px': additionalSticksYPos, 'width.px': nLength, 'height.px': (nLength * 0.147), 'transform': 'rotate(-90deg)'}"></div>
            </div>
        </div>
    </div>
</div>

<!-- <div id="iPkgContainer{{packageData.index}}" class="cPkgContainer">
    <div class="cPackage" id="iPackage{{packageData.index}}" (click)="checkAnswer()">{{packageData.message}}</div>
</div> -->