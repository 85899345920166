<!-- Copyright © Marvsoft LLP | All Rights Reserved -->

<div class="cGameContainer">
    <div (click)='back(false)' class="cClose">X</div>

    <div class="cLogo"></div>
    <div>
    <div class="cTitle cInformation" style="margin-left:0.3vh;">{{getGameScore()}} : ציון</div>
    <div id="iScoreCounts" class="cInformation" style="margin-left:0.3vh">{{getScore()}}<br></div>
    <div class="cTime cInformation">
        <span id="iMin" style="margin-left:0.3vh;">{{strMin}}</span><b> : </b>
        <span id="iSec">{{strSec}} <br></span>
    </div>
    </div>

    <ng-template appPackage (actionToParent)="actionToParent($event)"></ng-template>
    <div class="cLeftVerBar cVerticalBar"></div>
    <div class="cRightVerBar cVerticalBar"></div>

    <div class="cSpeedControl">
        <div class="cSpeedDown"  (click)="speedDownAllActivePkg()">–</div>
        <div>Speed</div>
        <div class="cSpeedUp" (click)="speedUpAllActivePkg()">+</div>
    </div>
    <div class="cUpArrow"></div>
    <div class="cSpeedInst">כפתור לשינוי קצב המסוע </div>

    <div class="cApplicationRooms">
        <div class="cQuestion" style="direction:rtl">{{curQn}}</div>
    </div>

    <div class="blinking"></div>
    <div class="cError">
        <span style="text-align: right;">תקלה!

            להמשך עבודה החזירו את
            "המפגע" חזרה למקומו</span>
    </div>
    <div class="cNest"></div>
    <div class="cDove" cdkDragBoundary=".cGameContainer" cdkDrag 
        (cdkDragEnded)="dragEnded($event)"></div>

    <!-- <div class="cStart">
        <span class="cStartBtn" (click)="startGame($event)">Start</span>
    </div> -->
</div>