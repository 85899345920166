import { CardData } from './../.games/pair-game/piece-holder/card-data';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  public activityID: string;
  public pairScore: number;
  public pairTime: number;
  public pairInterval;
  public pairMoves: number;
  public pairPairs: number;
  public pairStage: number;
  public pairLastRunTime: number;
  public pairDisabledPieces: Array<any>;
  public pairRandomPieces: Array<any>;
  public pairDoneLoad: boolean;
  public currComponentIndex = 0;
  public totalAnswerd: number;
  public totalRightAnswered: number;
  constructor() { }
}
