import { gamesID } from './../../../.components/games.components/explain-dialog/explain-dialog.component';

import * as gameService from '../../../../assets/environments/service';

import { Component, Output, EventEmitter, Input, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CardData } from './card-data';
import { GlobalsService } from '../../../services/globals.service';
import { emit } from 'process';
// tslint:disable: max-line-length
// tslint:disable: radix
// tslint:disable: prefer-for-of

@Component({
  selector: 'ms-piece-holder',
  templateUrl: './piece-holder.component.html',
  styleUrls: ['./piece-holder.component.scss']
})
export class PieceHolderComponent implements OnInit {
  nAnswerAttempts = 0; // number of guesses for a chosen question
  ansCorrectFirstAttempt = 0; // number of correct answers on first try
  currentGameID = 'jyeKPwy8f2CUmq1emCM9';
  arrQuestions: CardData[] = [];
  arrAnswers: CardData[] = [];
  arrPieces: CardData[];
  cardImg = 'assets/images/card.png'; // 'https://firebasestorage.googleapis.com/v0/b/yooz-develop.appspot.com/o/jigsawBackgrounds%2F01RmT7atorOL0i91VM8e54NWkEJ2?alt=media&token=0d2a82d6-a08f-412f-bd6a-0e9f8549ab25'
  strImgSuffix = '.png';
  nCols = 2;
  nTotalPieces = 4;
  nPieceMarginPer = 2;
  nTotalpiecesSelected = 0;

  nColWidth: number;
  arrSelPiece: number[] = [];
  nCorrect = 0;
  audCorrect = new Audio('assets/audios/clapping-hands.mp3');
  audWrong = new Audio('assets/audios/wrong.mp3');
  @ViewChildren('pieces') pieces: QueryList<any>;
  @Input() bIsActive = false;
  @Output() moveEvent = new EventEmitter<number>();
  @Output() pairsEvent = new EventEmitter<number>();
  @Output() gameCompleted =
              new EventEmitter<{endStage: boolean, ansCorrectFirstAttempt: number}>();
  @Output() startGame = new EventEmitter();
  user;
  // Constructor calls init function to intilise the pieces.
  constructor(public dialog: MatDialog, private GlobalsService: GlobalsService) {
    this.GlobalsService.pairLastRunTime = 0;
    this.GlobalsService.pairScore = 0;
    this.GlobalsService.pairMoves = 0;
    this.GlobalsService.pairPairs = 0;
    this.GlobalsService.pairStage = 0;
  }

  ngOnInit(): Promise<void> {
    this.GlobalsService.currComponentIndex = 0;
    return gameService.loadGame(this.currentGameID).then(docs => {
      const scoreDoc = docs.scoreDoc;
      const ConfigDoc = docs.configurationDoc;
      if (scoreDoc.exists){
        this.GlobalsService.pairLastRunTime = scoreDoc.data().gameTimeSecond;
        this.GlobalsService.pairScore = scoreDoc.data().gameScore;
        this.GlobalsService.pairMoves = scoreDoc.data().gameMoves;
        this.GlobalsService.pairPairs = scoreDoc.data().gamePairs;
        this.GlobalsService.pairStage = scoreDoc.data().gameStage;
        this.GlobalsService.pairDisabledPieces = scoreDoc.data().gameDisabledPieces;
        this.GlobalsService.pairRandomPieces = scoreDoc.data().gameRandomPieces;
        this.nTotalpiecesSelected = this.GlobalsService.pairDisabledPieces.length;
      }
      else{
        this.GlobalsService.pairLastRunTime = 0;
        this.GlobalsService.pairScore = 0;
        this.GlobalsService.pairMoves = 0;
        this.GlobalsService.pairPairs = 0;
        this.GlobalsService.pairStage = 1;
        this.GlobalsService.pairDisabledPieces = [];
        this.GlobalsService.pairRandomPieces = [];
      }
      this.GlobalsService.pairTime = this.GlobalsService.pairLastRunTime;
      let counter = 0;
      ConfigDoc.data().configurations.answers.forEach(element => {
        const localVar = {data: element, kind: 'text', index: counter, qst: false};
        counter++;
        this.arrAnswers.push(localVar);
      });
      ConfigDoc.data().configurations.questions.forEach(element => {
        const localVar = {data: element, kind: 'text', index: counter, qst: true};
        counter++;
        this.arrQuestions.push(localVar);
      });
      this.startGame.emit(true);
    });
  }

  // Initialize pieces
  init(): void {
    let begin = (this.GlobalsService.pairStage - 1) * this.nTotalPieces;
    let nLen = this.GlobalsService.pairStage * this.nTotalPieces; // this.arrQuestions.length;
    this.arrPieces = [];
    for (let i = begin; i < nLen; i++) {
      this.arrPieces.push(this.arrQuestions[i]);
      this.arrPieces.push(this.arrAnswers[i]);
    }
    if (this.GlobalsService.pairRandomPieces.length !== 0){
      this.arrPieces = this.GlobalsService.pairRandomPieces;
    }
    else{
      this.arrPieces = this.randomArray(this.arrPieces);
      this.GlobalsService.pairRandomPieces = this.arrPieces;
    }
    this.nColWidth = Math.floor((100 - this.nCols * this.nPieceMarginPer * 2) / this.nCols);
    this.nCorrect = 0;
    this.moveEvent.emit(this.GlobalsService.pairMoves);
    this.pairsEvent.emit(this.GlobalsService.pairPairs);
    let pieces :any = document.getElementsByClassName('cPieceCont');
    for (let piece of pieces){
      piece.style.pointerEvents = 'auto';
      piece.className = 'cPieceCont';
      (piece as HTMLElement).querySelector('.cCardHolder').className = 'cCardHolder cHide';
      // ((piece as HTMLElement).querySelector('.cPieceCover') as HTMLElement).style.display = 'block';
    }
  }
  back(): void{
    gameService.uploadScoreReport({gameRandomPieces: this.GlobalsService.pairRandomPieces, gameDisabledPieces: this.GlobalsService.pairDisabledPieces, gameMoves: this.GlobalsService.pairMoves, gamePairs: this.GlobalsService.pairPairs, gameStage: this.GlobalsService.pairStage, gameTimeSecond: this.GlobalsService.pairTime, gameScore: this.GlobalsService.pairScore, isDone:false, wrongAnswers: this.GlobalsService.totalAnswerd-this.GlobalsService.totalRightAnswered}).then(() =>{
      clearInterval(this.GlobalsService.pairInterval);
      window.location.href = '/#/';
    });
  }
  ngAfterViewInit() {
    this.pieces.changes.subscribe(t => {
      this.ngForRendred();
    })
  }
  ngForRendred(){
    for (let i = 0; i < this.GlobalsService.pairDisabledPieces.length; i++) {
      const objPiece = document.getElementById('iPiece_' + this.GlobalsService.pairDisabledPieces[i]) as HTMLElement;
      objPiece.style.pointerEvents = 'none';
      // (objPiece.querySelector('.cPieceCover') as HTMLElement).style.display = 'none';
      objPiece.querySelector('.cCardHolder').className = 'cCardHolder';
      objPiece.className += ' cDisabled';
      objPiece.querySelector('.cCardHolder').className += ' cDisabled';
    }
  }
  // Returns randomized array
  randomArray(arrTarget: CardData[]): CardData[] {
    const nLen = arrTarget.length;

    for (let i = 0; i < nLen; i++) {
      const nRnd = Math.floor(Math.random() * nLen);
      const strValue = arrTarget[nRnd];
      arrTarget[nRnd] = arrTarget[i];
      arrTarget[i] = strValue;
    }

    return arrTarget;
  }

  onPieceMouseDown(): boolean {
    return false;
  }

  // On piece clicked
  onPieceClicked(p_event): void {
    if (this.bIsActive) {
      const objPiece = p_event.currentTarget as HTMLElement;
      const nIndex = parseInt(objPiece.id.split('_')[1]);
      if (this.arrSelPiece.length < 2) {
        if( this.arrSelPiece.length === 1 &&
          this.arrPieces[this.arrSelPiece[0]]['qst'] === this.arrPieces[nIndex]['qst']) {
          this.dialog.open(ConfirmationDialog, {
            width: '280px',
            data: {
              msg: 'נדרשת בחירה של תשובה. (ריבוע טורקיז).',
              confirm: false,
              approval: 'המשך'
            },
            position: {
              top: '50px'
            }
          });
          return;
        }

        if (!this.arrSelPiece.length && !this.arrPieces[nIndex]['qst']) {
          this.dialog.open(ConfirmationDialog, {
            width: '280px',
            data: {
              msg: 'נדרשת בחירה של שאלה לפני תשובה. (ריבוע סגול).',
              confirm: false,
              approval: 'המשך'
            },
            position: {
              top: '50px'
            }
          });
          return;
        }
        const classNames = objPiece.className.split(' ');
        classNames.splice(2, 1);
        objPiece.className = classNames.join(' ') + ' animate-card-y';
        this.arrSelPiece.push(nIndex);
        objPiece.style.pointerEvents = 'none';
        // (objPiece.querySelector('.cPieceCover') as HTMLElement).style.display = 'none';
        objPiece.querySelector('.cCardHolder').className = 'cCardHolder';

        if (this.arrSelPiece.length === 2) {
          this.GlobalsService.pairMoves++;
          this.moveEvent.emit(this.GlobalsService.pairMoves);
          setTimeout(() => {
            this.openConfirmationDialog();
          }, 2000);
        }
      }
    }
  }

  // Update pieces status
  updatePiecesStatus(): void {
    this.GlobalsService.totalAnswerd++;
    if (this.validateSelection()) { // question and answer - matched
      this.GlobalsService.totalRightAnswered++;
      this.nTotalpiecesSelected += 2;
      this.nCorrect += 2;
      this.pairsEvent.emit(++this.GlobalsService.pairPairs);
      this.GlobalsService.pairScore +=
                this.nAnswerAttempts > 3 ? 0 :
                  Math.floor(100 / this.arrAnswers.length) /
                  Math.pow(2, this.nAnswerAttempts - 1);
      if (this.nAnswerAttempts === 1) this.ansCorrectFirstAttempt++;
      this.nAnswerAttempts = 0;
      for (let i = 0; i < this.arrSelPiece.length; i++) {
        this.GlobalsService.pairDisabledPieces.push(this.arrSelPiece[i]);
        const objPiece = document.getElementById('iPiece_' + this.arrSelPiece[i]) as HTMLElement;
        objPiece.className += ' cDisabled';
        objPiece.querySelector('.cCardHolder').className += ' cDisabled';
      }
      this.arrSelPiece.splice(0);
    }
    gameService.uploadScoreReport({
      gameRandomPieces: this.GlobalsService.pairRandomPieces,
      gameDisabledPieces: this.GlobalsService.pairDisabledPieces,
      gameMoves: this.GlobalsService.pairMoves,
      gamePairs: this.GlobalsService.pairPairs,
      gameStage: this.GlobalsService.pairStage,
      gameTimeSecond: this.GlobalsService.pairTime,
      arrSelPiece: this.arrSelPiece,
      gameScore: this.GlobalsService.pairScore,
      wrongAnswers:
        this.GlobalsService.totalAnswerd - this.GlobalsService.totalRightAnswered
    });

    if (this.nTotalpiecesSelected === this.arrPieces.length) {
      this.nTotalpiecesSelected = 0;
      this.gameCompleted.emit({endStage: true, ansCorrectFirstAttempt: this.ansCorrectFirstAttempt});
    }

  }

  setPiecesToNormalState(): void {
    for (let i = 0; i < this.arrSelPiece.length; i++) {
      const objPiece: HTMLElement = document.getElementById('iPiece_' + this.arrSelPiece[i]) as HTMLElement;
      if (objPiece.classList.contains('qst')) {
        continue;
      }
      const classesNames = objPiece.className.split(' ');
      classesNames.splice(classesNames.length - 1, 1);
      classesNames.push('animate-card-x');
      objPiece.className = classesNames.join(' ');
      objPiece.style.pointerEvents = 'auto';
      objPiece.querySelector('.cCardHolder').className += ' cHide';
      this.arrSelPiece.splice(i, 1);
    }
  }

  // Validate selection
  validateSelection(): boolean {
    this.nAnswerAttempts++;

    const selection1 = this.arrPieces[this.arrSelPiece[0]];
    const selection2 = this.arrPieces[this.arrSelPiece[1]];
    let correct = false;
    let ansIndex: number = this.arrAnswers.map(val => val.index).indexOf(selection1.index);
    let question = selection2;
    let answer = selection1;
    let selAnsCard = 0; // assuming selection1 is the answer, night change after the 'if' check

    if (ansIndex !== -1) { // selection1 is an answer
      correct =  this.arrQuestions.map(val => val.index).indexOf(selection2.index) === ansIndex;
    }
    else { // selection1 is a question
      ansIndex = this.arrAnswers.map(val => val.index).indexOf(selection2.index);
      selAnsCard = 1;
      question = selection1;
      answer = selection2;
      correct = this.arrQuestions.map(val => val.index).indexOf(selection1.index) === ansIndex;
    }
    this.arrPieces[this.arrSelPiece[selAnsCard]].data;
    if (correct){
      this.audCorrect.load();
      this.audCorrect.play();
    }
    else{
      // reset wrong answer
      const objPiece = document.getElementById('iPiece_' + this.arrSelPiece[selAnsCard]) as HTMLElement;
      const classesNames = objPiece.className.split(' ');
      classesNames.splice(classesNames.length - 1, 1); // remove animation of y-axis
      classesNames.push('animate-wrong-answer');
      objPiece.className = classesNames.join(' ');

      objPiece.style.pointerEvents = 'auto';
      objPiece.querySelector('.cCardHolder').className += ' cHide';
      this.arrSelPiece.splice(selAnsCard, 1);
      this.audWrong.load();
      this.audWrong.play();
    }

    gameService.uploadDetailedReport(
      this.arrQuestions.map(val => val.index).indexOf(question.index),
        { question: question.data,
          userAnswer: answer.data,
          isCorrectAnswer: correct},
      this.arrAnswers.map(val => val.index).indexOf(answer.index).toString()
    );
    return correct;
  }

  // Open confirmation dialog
  openConfirmationDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '280px',
      data: {
        msg: 'האם תשובתך סופית?',
        confirm: true,
        approval: 'כן',
      },
      position: {
        top: '50px'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1){
        this.updatePiecesStatus();
      }
      else{
        this.setPiecesToNormalState();
      }
    });
  }
}


@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.html',
  styleUrls: ['./piece-holder.component.scss']
})
export class ConfirmationDialog {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data:
                              {msg: string, confirm: boolean, approval: boolean}) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
