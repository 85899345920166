import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
// tslint:disable: radix

@Component({
  selector: 'app-training-footer',
  templateUrl: './training-footer.component.html',
  styleUrls: ['./training-footer.component.css']
})
export class TrainingFooterComponent implements OnInit {

  @Input() score = 0;
  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  moveToLeaderBoard(): void{
    // window.location.href = 'assets/leaderboard/index.html';
    this.router.navigateByUrl("leaderboard")
  }
}
