import { TrueFalseComponent } from './.games/true-false/true-false.component';
import { FinalScreenComponent } from './.games/matches/final-screen/final-screen.component';
import { MatchesPuzzleComponent } from './.games/matches/matches-puzzle/matches-puzzle.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { SatisfactionSurveyComponent } from './.games/satisfaction-survey/satisfaction-survey.component';
import { CourseEndComponent } from './.components/games.components/course-end/course-end.component';
import { ComponentScreenComponent } from './.components/games.components/component-screen/component-screen.component';
import { FactoryComponent } from './.games/factory-game/factory/factory.component';
import { GameBoardComponent } from './.games/pair-game/game-board/game-board.component';
import { ConfirmationDialog, PieceHolderComponent } from './.games/pair-game/piece-holder/piece-holder.component';
import { DashboardComponent } from './.screens/dashboard/dashboard.component';
import { LoginComponent } from './.screens/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LeaderboardComponent } from './.screens/leaderboard/leaderboard.component';
import { TrainingGamesComponent } from './.components/games.components/training-games/training-games.component';
import { CourseDashboardComponent } from './.components/games.components/course-dashboard/course-dashboard.component';

const routes: Routes = [
  {path: '404', component: NotfoundComponent},
  {path : 'dashboard' , component : DashboardComponent, children: [
    {path: 'games', component: TrainingGamesComponent},
    {path: 'course-map', component: CourseDashboardComponent},
    {path: 'course-component', component: ComponentScreenComponent},
    {path: 'course-end', component: CourseEndComponent},
    {path: 'course-survey', component: SatisfactionSurveyComponent}
  ]},
  { path: 'matches', children: [
    { path: 'game', component: MatchesPuzzleComponent },
    { path: 'final-screen', component: FinalScreenComponent },
  ] },
  {path: 'true-false-game', component: TrueFalseComponent},
  {path : 'login' , component : LoginComponent},
  {path : 'leaderboard' , component : LeaderboardComponent},
  {path : 'pair-game', component: GameBoardComponent},
  {path : 'factory-game', component: FactoryComponent},
  {path : '' , component : AppComponent},
  {path: '**', redirectTo: '/404'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash : true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
