var _firebaseConfigg;
try {
  // Firebase App (the core Firebase SDK) is always required and
  // must be listed before other Firebase SDKs
  var firebase = require("firebase/app");

  // Add the Firebase products that you want to use
  require("firebase/auth");
  require("firebase/firestore");
  require("firebase/analytics");
  var _firebaseConfigg = require("../environments/env.js");
} catch (error) {

}




var _componentIndex = 0
var _currActivity = null;
var _thisgameId;
var _db
if(_firebaseConfigg == null){
  _firebaseConfigg = firebaseConfig
}
firebase.initializeApp(_firebaseConfigg);
firebase.analytics();
_db = firebase.firestore();
var _thisuser
function loadGame(gameId){
  return getCurrentUser(firebase.auth()).then( _user => {
    _thisgameId = gameId
    _thisuser = _user
    if (_thisuser) {
      return _db.collection('users').doc(_thisuser.uid).get().then(_userDoc => {
        if(_userDoc.exists){
          _currActivity = _userDoc.data().currActivity;
          return _db.collection('users').doc(_thisuser.uid).collection('activities').doc(_userDoc.data().currActivity).get().then(_userActivityDoc=>{
            if(_userActivityDoc.exists){
              if (_userActivityDoc.data().currComponent != null){
                _componentIndex = _userActivityDoc.data().currComponent
                return _db.collection("scoreReport").doc(_userDoc.data()["currActivity"] + _thisuser.uid + _thisgameId + _userActivityDoc.data().currComponent).get().then(_scoreDoc => {
                  if(!_scoreDoc.exists || _scoreDoc.data().isDone!==true){
                    return _db.collection('activities').doc(_userDoc.data()["currActivity"]).get().then(_activityDoc => {
                      if(_activityDoc.data()['end time'].toDate() > new Date() && _activityDoc.data().componentsConfiguritionSets[_componentIndex].componentId === 'Uv34KePwYvCxVC9gToWu'){
                        return _db.collection('customers').doc(localStorage.getItem('customerId')).collection('componentsConfigurationSets').doc(_activityDoc.data().componentsConfiguritionSets[_componentIndex].configId).get().then(_componentConfDoc => {
                          if(_componentConfDoc.data().configurations['configId'] != null && _componentConfDoc.data().configurations['configId'] != 'notConfigurability'){
                            return loadConfigurations(_componentConfDoc.data().configurations['configId'], _componentConfDoc.data().configurations['gameId']).then( _configDoc => {
                              return {configurationDoc: _configDoc, scoreDoc: _scoreDoc}
                            })
                          }
                          else{
                            return {scoreDoc: _scoreDoc}
                          }
                        })
                      }
                      else{
                        _back()
                      }
                    })
                  }
                  else{
                    _back()
                  }
                })
              }
              else{
                if (_userActivityDoc.data().currGame != null){
                  _componentIndex = _userActivityDoc.data().currGame
                  return _db.collection("scoreReport").doc(_userDoc.data()["currActivity"] + _thisuser.uid + _thisgameId + _componentIndex).get().then(_scoreDoc => {
                    if(!_scoreDoc.exists || _scoreDoc.data().isDone!==true){
                      return _db.collection('activities').doc(_userDoc.data().currActivity).get().then(_activityDoc => {
                        if(_activityDoc.data()['end time'].toDate() > new Date()){
                          if(_activityDoc.data().gamesConfigurationSets[_componentIndex] != null){
                            if(_activityDoc.data().gamesConfigurationSets[_componentIndex].configId != null && _activityDoc.data().gamesConfigurationSets[_componentIndex].configId != 'null'){
                              return loadConfigurations(_activityDoc.data().gamesConfigurationSets[_componentIndex].configId, _activityDoc.data().gamesConfigurationSets[_componentIndex].gameId).then( _configDoc => {
                                return {configurationDoc: _configDoc, scoreDoc: _scoreDoc}
                              })
                            }
                            else{
                              return{scoreDoc: _scoreDoc}
                            }
                          }
                          else{
                            _back();
                          }
                        }
                        else{
                          _back();
                        }
                      })
                    }
                    else{
                      _back()
                    }
                  })
                }
                else{
                  _back()
                }
              }
            }
            else{
              _back()
            }
          });
        }
        else{
          _back();
        }
      });
    }
    else{
      _back();
    }
  })
}

function loadConfigurations(configId, gameId){

  return _db.collection('customers').doc(localStorage.getItem('customerId')).collection('gamesConfigurationSets').doc(configId).get().then(_configurationDoc => {
    if(_configurationDoc.exists){
      if(_configurationDoc.data().gameId === gameId){
        return _configurationDoc;
      }
      else{
        _back();
      }
    }
    else{
      _back();
    }
  });
}

function uploadScoreReport(data){
  data['activityId'] = _currActivity
  data['gameId'] = _thisgameId
  data['componentIndex'] = _componentIndex
  data['userId'] = _thisuser.uid
 /*  console.log('_currActivity',_currActivity,'_thisuser.uid',_thisuser.uid,'_thisgameId',_thisgameId,'_componentIndex',_componentIndex); */
  return _db.collection("scoreReport").doc(_currActivity + _thisuser.uid + _thisgameId + _componentIndex).set(data)
}

function getActivityDoc(){
  return _db.collection("activities").doc(_currActivity).get();
}

function updateScoreReport(data){
  data['activityId'] = _currActivity
  data['gameId'] = _thisgameId
  data['componentIndex'] = _componentIndex
  data['userId'] = _thisuser.uid
  return _db.collection("scoreReport").doc(_currActivity + _thisuser.uid + _thisgameId + _componentIndex).update(data)
}

function uploadDetailedReport(currQuestion, data, currAnswer = ''){
  data['activityId'] = _currActivity;
  data['gameId'] = _thisgameId;
  data['componentIndex'] = _componentIndex;
  data['userId'] = _thisuser.uid;
  return _db.collection("gamesDetailedReport").doc(_currActivity + _thisuser.uid + _thisgameId + _componentIndex + currQuestion + currAnswer).set(data)
}

function getScore(){
  return _db.collection("scoreReport").doc(_currActivity + _thisuser.uid + _thisgameId + _componentIndex).get().then(_doc => {
    if(_doc.exists){
      return _doc.data()['gameScore']
    }
    else{
      return 0
    }
  });
}

function _back(){
  window.location = "/#"
}
try {
  module.exports = {
    getScore: getScore,
    uploadDetailedReport: uploadDetailedReport,
    uploadScoreReport: uploadScoreReport,
    updateScoreReport: updateScoreReport,
    loadGame: loadGame,
    getActivityDoc:getActivityDoc,
  };
} catch (error) {

}


function getCurrentUser(auth) {
  return new Promise((resolve, reject) => {
     const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
     }, reject);
  });
}
