// Copyright © Marvsoft LLP | All Rights Reserved

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appMSLevel]'
})
export class MSLevelDirective {

  constructor( public viewContainerRef: ViewContainerRef ) { }

}
