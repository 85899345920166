// Copyright © Marvsoft LLP | All Rights Reserved

export class StickStyle {
  H: any;
  V: any;
  BS: any;
  FS: any;

  constructor(H: any = null, V: any = null, BS: any = null, FS: any = null) {
    this.H = H;
    this.V = V;
    this.BS = BS;
    this.FS = FS;
  }
}
