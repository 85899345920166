<!-- Copyright © Marvsoft LLP | All Rights Reserved -->

<div class="cGameContainer">
    <div (click)='back()' class="cClose">X</div>

    <div class="cLogo"></div>
    <div class="cTitle">משחק הגפרורים</div>
    <div class="cProgressHolder">
        <div class="cProgressCmn cBack" (click)="moveBack()"></div>
        <div class="cProgressCmn cRefresh" (click)="replayLevel()"></div>
        <div class="cProgressCmn cMuteUnmute cUnMuted" (click)="toggleMuteUnmute()"></div>
        <div class="cProgressCtrl">
            <div id="iMoveCounts" class="cProgressBg">{{nMoves}}</div>
            <div id="iPairsCounts" class="cProgressBg">{{nCurLevel + 1}}</div>
        </div>
        <div class="cProgressTitle">
            <div class="cProgressCmn">מהלכים</div>
            <div class="cProgressCmn cLevelTitle">שלב</div>
        </div>
    </div>
    <div class="cSheep"></div>
    <div class="cGameHolder">
        <ng-template appMSLevel (actionToParent)="actionToParent($event)"></ng-template>
    </div>
</div>