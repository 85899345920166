import {  OnInit, ElementRef, ViewChild, AfterViewInit, Renderer2, Component } from '@angular/core';
import { DashboardService } from './../../../services/dashboard.service';
import { GlobalsService } from './../../../services/globals.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import {MatDialog} from '@angular/material/dialog';
import { ExplainDialogComponent } from '../explain-dialog/explain-dialog.component';
import { AngularFireAuth } from '@angular/fire/auth';
import * as texts from '../../../../assets/languages';
import { LoginService } from '../../../services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AreYouDialogComponent } from '../../dialogs/are-you-dialog/are-you-dialog.component';
// tslint:disable: max-line-length
// tslint:disable: prefer-for-of
// tslint:disable: radix
// tslint:disable: ban-types

export interface ComponentModel {
  componentId: string;
  configId: string;
  configurations: any;
}
@Component({
  selector: 'app-course-dashboard',
  templateUrl: './course-dashboard.component.html',
  styleUrls: ['./course-dashboard.component.css']
})
export class CourseDashboardComponent implements OnInit {
  startAnim = true;
  score = 0;
  currUid: string;
  components: Array<ComponentModel> = new Array();
  loading = true;
  intervals = [];
  dir = 'rtl';
  language;
  translation;
  user;
  currIndex;
  activityCode;
  inMap = true;
  haveLeaderBoard = false;
  constructor(private firestore: AngularFirestore,
              public dialog: MatDialog,
              private router: Router,
              private snackBar: MatSnackBar,
              private loginService: LoginService,
              private dashboardService: DashboardService) {
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
    this.loginService.login().then(user => {
      this.user = user;
      if (!(user && user.uid)){
        window.location.reload();
      }
      this.dashboardService.setUser(user);
      this.dashboardService.getActivityData().then(doc => {
        this.activityCode = doc.id;
        if(doc.data().haveLeaderBoard !== null){
          this.haveLeaderBoard = doc.data().haveLeaderBoard
        }
        this.setScreen(doc.data().layoutId, doc.data().customerUID);
        this.startTimer(doc.data()['end time']);
      });
    });
   }
   setScreen(layoutId: string, customerId: string): void{
    this.dashboardService.setCourseScreen(layoutId, customerId, this.activityCode).then(data => {
     this.components = data.localComponents;
     this.currIndex =  data.currComponentIndex;
     if (this.currIndex >= this.components.length){
      this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(this.activityCode).get().toPromise().then(userActivityDoc => {
        if (userActivityDoc.data().endTime != null){
          this.router.navigateByUrl('dashboard/course-end');
        }
        else{
          this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(this.activityCode).update({endTime: new Date()}).then(() => {
            this.router.navigateByUrl('dashboard/course-end');
          });
        }
      });
    }
    else{
      this.loading = false;
    }
     
     setTimeout( () => {
       this.startAnim = false;
     }, 1);
   });
  }
  moveToLeaderBoard(): void{
    this.router.navigateByUrl("leaderboard")
  }
  startTimer(endTime): void{
    this.intervals.push(setInterval(() => {
      if (endTime.toDate() < new Date()){
        this.router.navigateByUrl('dashboard/course-end');
      }
      if (document.getElementById('check') != null){
        clearInterval(this.intervals[0]);
      }
    }, 1000));
  }
  showComponent(index): void{
    if (index === this.currIndex){
      this.router.navigateByUrl('dashboard/course-component');
    }
    else {
      this.snackBar.open('אתה כרגע בתחנה מספר ' + (this.currIndex + 1), '', {duration: 2000, direction: 'rtl' });
    }
  }
  getLineStyle(): any{
    const lineHeight: string =  + 20 + (36.5 * (this.components.length - 1))  + 20 + '';
    const styles = {
      'height': lineHeight + 'vw'
    };
    return styles;
  }
  getTitleStyle(el, tester): any{
    const styles = {
      'top': -1 + this.countLines(el, tester) * -4 + 'vw'
    };
    return styles;
  }
  logoutFromActivity(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.leaveActivityTitle[this.language],
              content: this.translation.leaveActivityContent[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.firestore.collection('users').doc(this.user.uid).set({}).then(() => {
          localStorage.removeItem('tempCode');
          window.location.reload();
        });
      }
    });
  }

  logoutFromAccount(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.logoutTitle[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.loginService.logout().then(() => {
          window.location.reload();
        });
      }
    });
  }
  ngOnInit(): void {
  }
  countLines(el, tester): number {
    const divHeight = el.offsetHeight;
    const lineHeight = tester.offsetWidth * 4;
    const lines = divHeight / lineHeight;
    return lines;
 }
}
