import { FactoryService } from './../../../services/factory.service';
// Copyright © Marvsoft LLP | All Rights Reserved

import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupDialog } from '../factory/factory.component';
import { PackageData } from './package.data';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements AfterViewInit {
  @Output("actionToParent") actionToParent: EventEmitter<any> = new EventEmitter();
  nCurrentPos = 0;
  nTotalDistance = 0;
  setTimeoutIntrv: any;
  isPause = false;
  sMessage = 'Lorem Ipsum';
  packageData: PackageData;
  constructor(public dialog: MatDialog, private factoryService: FactoryService) { }
  ngAfterViewInit(): void {
    this.init();
  }
  action = (actionType: string, actionVal: any) => {
    switch (actionType) {
      case "index":
        this.packageData = actionVal.data;
        break;

      case "speedUp":
        this.packageData.speed = actionVal;
        break;
        
      case "play":
        this.isPause = false;
        this.startAnimation();
        break;

      case "pause":
        this.isPause = true;
        clearInterval(this.animatioInterval);
        break;
    }
  }

  addToPackageArr(): void{
    if (this.factoryService.packagCounter < this.factoryService.numOfQuestions){
      this.factoryService.packages.push(this);
    }
    else{
      this.factoryService.packages[this.factoryService.packagCounter % this.factoryService.numOfQuestions] = this;
    }
    this.factoryService.packagCounter++;
  }
  animatioInterval;
  init() {
    this.nTotalDistance = document.querySelector('.cGameContainer').clientWidth - document.querySelector(`#iPkgContainer${this.packageData.index} .cPackage`).scrollWidth;
    this.addToPackageArr();
    this.startAnimation();
  }

  getRandomNumberFromTo(nMin, nMax) {
    return nMin + (Math.random() * (nMax - nMin));
  }

  checkAnswer() {
 /*    if (true){
      const dialogRef = this.dialog.open(PopupDialog, {
        width: '250px',
        data: this.packageData.message
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result){
          this.actionToParent.emit(['checkAns', [(this as PackageComponent), this.packageData.message]]);
        }
      });
    } */
    this.actionToParent.emit(['checkAns', [(this as PackageComponent), this.packageData.message]]);
  }

  thisPackageContainer(): HTMLElement {
    return document.querySelector(`#iPkgContainer${this.packageData.index} .cPackage`) as HTMLElement;
  }

  startAnimation(){
    setTimeout(()=>{
      this.animatioInterval = setInterval(() => {        
        this.nCurrentPos += this.packageData.speed; 
        let packageItem: HTMLElement = document.querySelector(`#iPkgContainer${this.packageData.index} .cPackage`) as HTMLElement;
        if (packageItem) {
          packageItem.style.left = this.nCurrentPos + 'px';
        }
        else {
          clearInterval(this.animatioInterval)
        }
        if (this.nCurrentPos >= this.nTotalDistance) {
          this.destroy();
          clearInterval(this.animatioInterval)
        } 
      },30)
    },10)
  }


  // startAnimation = () => {
  //   this.nCurrentPos += this.packageData.speed/5;
  //   let packageItem: HTMLElement = document.querySelector(`#iPkgContainer${this.packageData.index} .cPackage`) as HTMLElement;
  //   let frontpackageItem: HTMLElement = document.querySelector(`#iPkgContainer${this.packageData.index+1} .cPackage`) as HTMLElement;

  //   if(packageItem){
  //     if(frontpackageItem){
  //       if()
  //     }
  //     else{
  //       packageItem.style.left = this.nCurrentPos + 'px';
  //     }
  //   }
  //   else
  //     clearTimeout(this.setTimeoutIntrv);

  //   if (this.nCurrentPos < this.nTotalDistance)
  //     this.setTimeoutIntrv = setTimeout(this.startAnimation, 1);
  //   else
  //     this.destroy();
  // }


  pauseAnimation = () => {
    clearInterval(this.animatioInterval);
  }

  destroy() {
    this.actionToParent.emit(['removeMe', [(this as PackageComponent)]]);
  }
}
