const avaibleLanguages = ['he', 'en'];
const texts = {
    fillCode: {
        he: 'הכנס קוד',
        en: 'Fill code',
    },
    code: {
        he: 'קוד',
        en: 'Code'
    },
    cancel: {
        he: 'ביטול',
        en: 'Cancel'
    },
    leaderboard: {
        he: 'לוח התוצאות',
        en: 'Leaderboard'
    },
    enterGame: {
        he: 'כניסה למשחק',
        en: 'Enter game'
    },
    exmaple: {
        he: 'דוגמה',
        en: 'Exmaple'
    },
    courseDoneTitle: {
        he: 'סיימת את הפעילות. כל הכבוד!',
        en: 'you finished the course.'
    },
    otherComments: {
        he: 'עוד הערות?',
        en: 'Any comments?'
    },
    enterVideo: {
        he: 'למעבר לסרטון',
        en: 'Move to video'
    },
    enterSurvey: {
        he: 'למעבר לסקר',
        en: 'Move to survey'
    },
    enterPresentation: {
        he: 'למעבר למצגת',
        en: 'Move to presentation'
    },
	ballGameBonusTitle: {
		he: 'בונוס על הכדור הכחול',
		en: 'The Blue Ball Bonus'
	},
	ballGameBonusContent: {
		he: 'במידה ותענה נכון על 2 השאלות של הכדורים הכחולים תקבל/י נקודות בונוס',
		en: 'If you will answer correctly the 2 blue ball questions you\'ll get an extra points bonus.'
	},
    chooseGroup: {
        he: 'בחר קבוצה',
        en: 'Choose group'
    },
    chooseDepart: {
        he: 'בחר מחלקה',
        en: 'Choose department'
    },
    mustChooseGroup: {
        he: 'חייב לבחור קבוצה',
        en: 'Must choose group'
    },
    group: {
        he: 'קבוצה',
        en: 'group'
    },
    mustFillCode: {
        he: 'חייב להכניס קוד',
        en: 'Must fill code'
    },
    codeNotFound: {
        he: 'הקוד לא נמצא',
        en: 'Code not found'
    },
    next: {
        he: 'הבא',
        en: 'Next'
    },
    previous: {
        he: 'קודם',
        en: 'Previous'
    },
    finish: {
        he: 'סיום',
        en: 'Finish'
    },
    finishComponentTitle: {
        he: 'האם לסיים את התחנה?',
        en: 'Finish this station?'
    },
    finishComponentContent: {
        he: 'מרגע שתלחצו על סיום לא תוכלו לחזור לתחנה זו.',
        en: 'when you click on finish station you won\'t be able to go back to this station again.'
    },
    fillName: {
        he: 'הכנס שם',
        en: 'Fill name'
    },
    name: {
        he: 'שם',
        en: 'Name'
    },
    nameExample: {
        he: 'לדוגמה: ישראל ישראלי',
        en: 'Ex. Israel Israeli'
    },
    codeExample: {
        he: 'לדוגמה: 1A2B3C',
        en: 'Ex. 1A2B3C'
    },
    allTrue: {
        he: 'אם כל הנתונים נכונים יש ללחוץ סיום',
        en: 'If all the details are true click finish'
    },
    memoryLabel: {
        he: 'זיכרון',
        en: 'Memory Game'
    },
    score: {
        he: 'תוצאה',
        en: 'Score'
    },
    leftClicks: {
        he: 'יתרת לחיצות',
        en: 'Left clicks'
    },
    push: {
        he: 'לחץ',
        en: 'Push'
    },
    switch: {
        he: 'החלף',
        en: 'switch'
    },
    logout: {
        he: 'התנתק',
        en: 'Logout'
    },
    leaveActivityContent: {
        he: 'האם אתה בטוח שברצונך להתנתק מפעילות זאת?',
        en: 'Are you sure you want to logout from this activity?'
    },
    leaveActivityTitle: {
        he: 'האם להתנתק?',
        en: 'Leave activity?'
    },
    logoutTitle: {
        he: 'האם ברצונך להתנתק ממשתמש זה?',
        en: 'Log out?'
    },
    enter: {
        he: "לחץ",
        en: "Enter"
    },
    question: {
        he: "שאלה",
        en: "Question"
    },
    questionScore: {
        he: "ציון",
        en: "score"
    },
    puzzleScore: {
        he: "ציון לפאזל",
        en: "Puzzle score"
    },
    logoutFromAccount: {
        he: "התנתקות מהמשתמש",
        en: "Logout from this account"
    },
    switchActivity: {
        he: "החלפת פעילות",
        en: "Switch activity"
    },
    Signin: {
        he: "התחברות",
        en: "Sign in"
    },
    switchActivityTitle: {
        he: "להחליף פעילות?",
        en: "Switch activity?"
    },
    switchActivityContent: {
        he: "המשתמש שהתחברת אליו כבר בפעילות קיימת, האם להחליף פעילות או להישאר בפעילות?",
        en: "The account that you signed in to already in activity, are you sure you want to switch activity or stay?"
    },
    switchActivityOk: {
        he: "החלף",
        en: "Switch"
    },
    switchActivityCancel: {
        he: "הישאר",
        en: "Stay"
    },
};

try {
    module.exports = texts;
} catch (error) {

}
