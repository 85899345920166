import { FormGroup, FormControl } from '@angular/forms';
// tslint:disable: max-line-length

import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as texts from '../../../assets/languages';
import { LoginService } from '../../services/login.service';
import { AreYouDialogComponent } from '../../.components/dialogs/are-you-dialog/are-you-dialog.component';
import { ComponentModel } from '../../.components/games.components/course-dashboard/course-dashboard.component';
import { DashboardService } from '../../services/dashboard.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-satisfaction-survey',
  templateUrl: './satisfaction-survey.component.html',
  styleUrls: ['./satisfaction-survey.component.css']
})
export class SatisfactionSurveyComponent implements OnInit {
  language;
  translation;
  dir = 'rtl';
  user;
  currIndex;
  components: Array<ComponentModel> = new Array();
  activityCode;
  loading = true;
  slidersControls = new FormGroup({});
  commentsControl = new FormControl();
  questions = ['באיזו מידה מודול הקליטה תרם להכרות שלך עם הארגון', 'באיזו מידה הוא תרם להכרות שלך עם הצוות', 'איזה ציון היית נותנ/ת לחווית הלמידה'];
  constructor(@Inject(DOCUMENT) private document: Document,
              private firestore: AngularFirestore,
              public dialog: MatDialog,
              private router: Router,
              private snackBar: MatSnackBar,
              private loginService: LoginService,
              private dashboardService: DashboardService) {
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
    this.loginService.login().then(user => {
      this.user = user;
      if (!(user && user.uid)){
        window.location.reload();
      }
      this.dashboardService.setUser(user);
      this.dashboardService.getActivityData().then(doc => {
        this.activityCode = doc.id;
        this.setScreen(doc.data().layoutId, doc.data().customerUID);
      });
    });
  }
  back(): void {
    this.router.navigateByUrl('dashboard/course-map');
  }
  setScreen(layoutId: string, customerId: string): void{
    this.dashboardService.setCourseScreen(layoutId, customerId, this.activityCode).then(data => {
      this.components = data.localComponents;
      this.currIndex =  data.currComponentIndex;
      if(this.components[this.currIndex].configurations.surveyQuestions){        
        this.questions = this.components[this.currIndex].configurations.surveyQuestions;
      }
      if (this.currIndex >= this.components.length){
        this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(this.activityCode).get().toPromise().then(userActivityDoc => {
          if (userActivityDoc.data().endTime != null){
            this.router.navigateByUrl('dashboard/course-end');
          }
          else{
            this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(this.activityCode).update({endTime: new Date()}).then(() => {
              this.router.navigateByUrl('dashboard/course-end');
            });
          }
        });

      }
      else if (this.components[this.currIndex].componentId !== 'ruvelQ6mbSE1Nm1W0dkc'){
        this.router.navigateByUrl('dashboard/course-map');
      }
      else{
        this.loading = false;
      }
      let i = 0;
      for (let question of this.questions){
        this.slidersControls.addControl(i + '', new FormControl());
        i++;
      }
    });
  }

  finish(): void{
    const doc = {answers: [], userId: this.user.uid, activityId: this.activityCode, comments: this.commentsControl.value};
    let i = 0;
    for (let question of this.questions){
      doc.answers[i] = this.slidersControls.get(i + '').value;
      i++;
    }
    this.firestore.collection('activitySatisfactionReport').add(doc).then(() => {
      this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(this.activityCode).update({currComponent: this.currIndex + 1}).then(() => {
        this.router.navigateByUrl('dashboard/course-map');
      });
    });
  }
  ngOnInit(): void {
  }

}
