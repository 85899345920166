// tslint:disable: forin
// tslint:disable: max-line-length
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { keyframes } from '@angular/animations';
import * as texts from 'src/assets/languages';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { AreYouDialogComponent } from 'src/app/.components/dialogs/are-you-dialog/are-you-dialog.component';
import { MatDialog } from '@angular/material/dialog';

interface Result{
  name: string;
  score: number;
  place: number;
}

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css']
})

export class LeaderboardComponent implements OnInit {
  activityLogo = '';
  activityName = '';
  dir = 'rtl';
  language;
  translation;
  results: Result[] = [];
  finished = true;
  notfinished = true;
  user;
  loading = true;
  constructor(private firestore: AngularFirestore, private loginService: LoginService, private router: Router, public dialog: MatDialog) {
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
    this.loginService.login().then(user => {
      this.user = user;
      if (user && user.uid){
        this.firestore.collection('users').doc(user.uid).get().toPromise().then(userDoc => {
          if (userDoc.data().currActivity == null){
            router.navigateByUrl('login');
          }
          else{
            if (userDoc.data().currActivity == null){
              window.location.reload();
            }
            else{
              this.firestore.collection('activities').doc(userDoc.data().currActivity).get().toPromise().then(doc => {
                this.activityLogo = doc.data().activityLogo;
                this.activityName = doc.data().activityName;
                // if (doc.data().activityType === 'course'){
                //   router.navigateByUrl('/');
                // }
                if (doc.data()['end time'].toDate() > new Date()){
                  this.finished = false;
                }
                else{
                  this.notfinished = false;
                }
                this.loading = false;
                const scores = [];
                if (doc.data().loginType === 'solos'){
                  doc.ref.collection('leaderboard').get().then( leaderboard => {
                    leaderboard.docs.forEach( user => {
                      let userScore = 0;
                      for (let key in user.data()){
                        userScore += user.data()[key];
                      }
                      scores.push({id: user.id, score: userScore});
                    });
                  }).then( () => {
                    scores.sort( (a, b) => a.score - b.score);
                    scores.reverse();
                    let i = 0;
                    scores.forEach(score => {
                      i++;
                      const loc = i;
                      firestore.collection('users').doc(score.id).collection('activities').doc(userDoc.data().currActivity).get().toPromise().then( userDoc => {
                        this.results.push({name: userDoc.data().name, score: Math.floor(score.score), place: loc});
                      });
                    });
                  });
                }
                else{
                  doc.ref.collection('leaderboard').get().then( leaderboard => {
                    const groups = {};
                    leaderboard.docs.forEach( group => {
                      for (let scoreKey in group.data()){
                        const scoreValue = group.data()[scoreKey];
                        const path = scoreKey.split('.', 2);
                        if (groups[group.id] == null){
                          groups[group.id] = {};
                          groups[group.id][path[1]] = scoreValue;
                        }
                        else if (groups[group.id][path[1]] != null){
                          groups[group.id][path[1]] += scoreValue;
                        }
                        else{
                          groups[group.id][path[1]] = scoreValue;
                        }
                      }
                    });
                    const scores = [];
                    for (let group in groups){
                      let sum = 0;
                      let counter = 0;
                      for (let user in groups[group]){
                        sum += groups[group][user];
                        counter++;
                      }
                      scores.push({group, score: sum / counter});
                    }
                    scores.sort( (a, b) => a.score - b.score);
                    scores.reverse();
                    let i = 0;
                    scores.forEach(score => {
                      i++;
                      const loc = i;
                      if (doc.data().loginType === 'groups'){
                        this.results.push({name: (this.translation.group[this.language] + ' ' + score.group), score: Math.floor(score.score), place: loc});
                      }
                      else{
                        this.results.push({name: score.group, score: Math.floor(score.score), place: loc});
                      }
                    });
                  });
                }
              });
            }
          }
        });
      }
      else{
        window.location.reload();
      }
    });
  }
  ngOnInit(): void {
  }
  back(): void{
    this.router.navigateByUrl('dashboard');
  }
  logoutFromActivity(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.leaveActivityTitle[this.language],
              content: this.translation.leaveActivityContent[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.firestore.collection('users').doc(this.user.uid).set({}).then(() => {
          localStorage.removeItem('tempCode');
          window.location.reload();
        });
      }
    });
  }

  logoutFromAccount(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.logoutTitle[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.loginService.logout().then(() => {
          window.location.reload();
        });
      }
    });
  }
  
}

