import { DashboardService } from './../../../services/dashboard.service';
import { GlobalsService } from './../../../services/globals.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import {MatDialog} from '@angular/material/dialog';
import { ExplainDialogComponent } from '../explain-dialog/explain-dialog.component';
import { AngularFireAuth } from '@angular/fire/auth';
import * as texts from 'src/assets/languages';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'src/app/services/login.service';
import { userInfo } from 'os';
import { AreYouDialogComponent } from '../../dialogs/are-you-dialog/are-you-dialog.component';
// tslint:disable: max-line-length
// tslint:disable: prefer-for-of
// tslint:disable: radix
export interface Game {
  src: string;
  score: string;
  enable: boolean;
  name: string;
}
@Component({
  selector: 'app-training-games',
  templateUrl: './training-games.component.html',
  styleUrls: ['./training-games.component.scss']
})
export class TrainingGamesComponent implements OnInit  {
  startAnim = true;
  score = 0;
  logoSrc: string;
  currUid: string;
  games: Map<string, Game> = new Map();
  loading = true;
  intervals = [];
  dir = 'rtl';
  language;
  translation;
  user;
  gamesArr = [];
  constructor(private firestore: AngularFirestore,
              public dialog: MatDialog,
              private router: Router,
              private snackBar: MatSnackBar,
              private loginService: LoginService,
              private dashboardService: DashboardService) {
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
    this.loginService.login().then(user => {
      this.user = user;
      if (!(user && user.uid)){
        window.location.reload();
      }
      this.dashboardService.setUser(user);
      this.dashboardService.getActivityData().then(doc => {
        if (doc.data().activityType !== 'games'){
          this.router.navigateByUrl('dashboard').then(() => {
            window.location.reload();
          });
        }
        else if ( doc.data()['end time'].toDate() < new Date()){
          this.router.navigateByUrl('leaderboard');
          return;
        }
        this.logoSrc = doc.data().logoUrl;
        this.setScreen(doc.data().layoutId, doc.data().customerUID);
        this.startTimer(doc.data()['end time']);
      });
    });
  }
  ngOnInit(): void{
  }

  moveToLeaderBoard(): void{
    clearInterval(this.intervals[0]);
    this.router.navigateByUrl('leaderboard');
  }
  setScreen(layoutId: string, customerId: string): void{
     this.dashboardService.setGamesScreen(layoutId, customerId).then(data => {
      this.gamesArr = data.gamesArr;
      this.score = data.score;
      this.games = data.gamesData;
      this.loading = false;
      setTimeout( () => {
        this.startAnim = false;
      }, 1);
    });
  }
  startTimer(endTime): void{
    this.intervals.push(setInterval(() => {

      const timerRef = document.getElementById('timer');
      if (!timerRef) { return; }

      const now  = new Date();
      const dif =  endTime.toDate().getTime() - now.getTime();
      const timeDelta = dif / 1000;
      timerRef.innerHTML = this.secondsToHMS(timeDelta);
      const a = timerRef.innerHTML.split(':');
      if (endTime.toDate() < new Date()){
        this.router.navigateByUrl('leaderboard');
        clearInterval(this.intervals[0]);
      }
    }, 1000));
  }

  secondsToHMS(secs): string {
    const secNum = parseInt(secs, 10);
    const hours   = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds].map(v => v < 10 ? '0' + v : v).join(':');
  }
  getDeltaInMinutes(date1, date2): number{
    let delta =  date1 - date2;
    delta = Math.round(delta / 60000);
    return delta;
  }
  showExplanation(gameId, i): void{
    if (this.games.get(gameId).enable){
      const dialogRef = this.dialog.open(ExplainDialogComponent, {
        width: '400px',
        data: {gameId, index: i}
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    else {
      this.snackBar.open('לא ניתן לשחק במשחק זה', '', {duration: 2000, direction: 'rtl' });
    }
  }

  logoutFromActivity(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.leaveActivityTitle[this.language],
              content: this.translation.leaveActivityContent[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.firestore.collection('users').doc(this.user.uid).set({}).then(() => {
          localStorage.removeItem('tempCode');
          window.location.reload();
        });
      }
    });
  }

  logoutFromAccount(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.logoutTitle[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.loginService.logout().then(() => {
          window.location.reload();
        });
      }
    });
  }
}
