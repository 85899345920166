
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GlobalsService } from './../../../services/globals.service';
export const gamesID = {ballGame: 'u7HhTRF7IjznvxRQ6JXw', jigsaw: 'I7wBOeWZqdDXUkpIN3g2', memory: 'L3txSVIbfBX93EoqEj7C'};
import * as texts from 'src/assets/languages';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoginService } from 'src/app/services/login.service';
import { type } from 'os';
// tslint:disable: max-line-length

interface explanationTexts{
  path: string;
  title: string;
  description: string;
}
@Component({
  selector: 'app-explain-dialog',
  templateUrl: './explain-dialog.component.html',
  styleUrls: ['./explain-dialog.component.css']
})
export class ExplainDialogComponent implements OnInit {

  showVid = false;
  videoSource = 'assets/videos/explanationilanit.mp4';
  videoAvaible = false;
  explanation: string = '';
  title: string = '';
  dir = 'rtl';
  language;
  translation;
  path;
  user;
  index;
  currActivity;
  //   {gameID: gamesID.jigsaw, data: {path: 'assets/games/jigsaw/index.html', title: 'פאזל טריויה', description: 'ענו על השאלות, קבלו חלקי פאזל של התמונה והרכיבו אותה'}
  // {path: 'assets/games/Rings/index.html', title: 'מה במסגרת', description: ' לפניכם 20 שאלות אודות  תחומים שונים הקשורים לישראל.\n\nבמענה לשאלה גרור/י את המסגרת הכחולה אל עבר התמונה והנח אותה במיקום הנכון.\n\nלרשותך 20 שניות לכל שאלה.\nלחצ/י אשר\nכל תשובה נכונה 5 נקודות. לחץ על כפתור דוגמא למטה'},
  //   {path: 'assets/games/ballgame/index.html', title: 'משחק הכדורים', description: 'המטרה לענות על כל השאלות המופיעות במסגרת הצהובה.\n את התשובות המופיעות על הכדורים יש לדחוף באמצעות הכדור האדום הקטן.\n כאשר הכדור עם התשובה הנכונה יגע במסגרת הצהובה הוא יעלם ותקבלו ניקוד.\n בהצלחה'},
  //   {path: 'assets/games/brain memory/index.html', title: 'משחק הזכרון', description: 'עליך ללחוץ על כל הריבועים בהם מופיעים הצורות.\nניתן לבדוק כי לחצת את הכמות הרצויה בצפיה ב\'יתרת הלחיצות\'.\nכל שלב שווה כ10 נקודות.\nניתן לשחק במשחק כמות בלתי מוגבלת. התוצאה הטובה ביותר היא זו שתופיע.\nבהצלחה'},
  //   {path: 'assets/games/trivia/index.html', title: 'אתגר הטריוויה', description: 'עליך ללחוץ על כל הריבועים בהם מופיעים הצורות.\nניתן לבדוק כי לחצת את הכמות הרצויה בצפיה ב\'יתרת הלחיצות\'.\nכל שלב שווה כ10 נקודות.\nניתן לשחק במשחק כמות בלתי מוגבלת. התוצאה הטובה ביותר היא זו שתופיע.\nבהצלחה'},

  constructor(private global: GlobalsService, private loginService: LoginService, public dialogRef: MatDialogRef<ExplainDialogComponent>, private firestore: AngularFirestore,
              @Inject(MAT_DIALOG_DATA) public data) {
      this.translation = texts;
      this.language = localStorage.getItem('language');
      this.dir = localStorage.getItem('dir');
      this.index = data.index;
      data = data.gameId;

      this.loginService.login().then( user => {
        this.user = user
        if (!(user && user.uid)){
          window.location.reload();
        }
        firestore.collection('games').doc(data).get().toPromise().then(gameDoc => {
          this.title = gameDoc.data().name[this.language];
          this.path = gameDoc.data().path;
          this.explanation = gameDoc.data().description[this.language];
          this.explanation = this.explanation.replace ('\\\'', '\'');
        });
        firestore.collection('users').doc(this.user.uid).get().toPromise().then(userDoc => {
          this.currActivity = userDoc.data().currActivity;
        })
      });
    }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  moveToGame(): void{
    if (this.index != null){
      this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(this.currActivity).update({currGame: this.index}).then(() => {
        window.location.href = this.path;
      });
    }
    else{
      window.location.href = this.path;
    }
  }
}
