<!-- Copyright © Marvsoft LLP | All Rights Reserved -->

<div class="cPieceHolder">
    <div #pieces id="iPiece_{{i}}" class="{{ item.qst ? 'cPieceCont qst' : 'cPieceCont ans'}}" [ngStyle]="{'width': nColWidth + '%'}" (click)="onPieceClicked($event)"
        (mousedown)="onPieceMouseDown()" *ngFor="let item of arrPieces; let i = index">
        <!-- <img class="cPieceContBg" src="assets/images/piece-bg.png"> -->
   <!--      <img class="cPieceCover" src="assets/images/piece-cover.png"> -->
        <!-- <img class="cPieceCover" src="assets/images/piece-cover.png"> -->
        <div class="cCardHolder cHide">
            <img *ngIf="item.kind == 'image'" class="cCardImg" src="{{item.data}}">
            <div *ngIf="item.kind == 'text'" class="cCardText">{{item.data}}</div>
        </div>
    </div>
</div>
