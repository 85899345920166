import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-final-screen',
  templateUrl: './final-screen.component.html',
  styleUrls: ['./final-screen.component.scss']
})
export class FinalScreenComponent implements OnInit {
  audCorrect: any = new Audio('assets/audios/clapping-hands.mp3');
  audSheep: any = new Audio('assets/audios/sheep_baa.mp3');

  constructor(private Router: Router) { }

  ngOnInit(): void {
    this.audCorrect.pause();
    this.audCorrect.currentTime = 1;
    this.audCorrect.play();

    this.audSheep.loop = true;
    this.audSheep.play();
    setTimeout(() => {
      this.audSheep.pause();
      this.Router.navigateByUrl('dashboard');
    }, 6000);
  }
  back(): void{
    this.Router.navigateByUrl('dashboard');
  }

}
