import { Router } from '@angular/router';
import { GlobalsService } from 'src/app/services/globals.service';
import { CardData } from './../piece-holder/card-data';
// Copyright © Marvsoft LLP | All Rights Reserved
// tslint:disable: max-line-length
import * as gameService from '../../../../assets/environments/service';

import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'ms-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.scss']
})
export class WatchComponent {
  @Input() nTotalStage: number = 3;
  currentGameID = 'jyeKPwy8f2CUmq1emCM9';
  @Input() strHour: string;
  @Input() strMin: string;
  @Input() strSec: string;
 constructor(public GlobalsService: GlobalsService, private firestore: AngularFirestore, private loginService: LoginService, private router: Router){}
  // getScore(){
  //   let nScore: number = Math.round(this.GlobalsService.pairPairs / 6 * 100 - (this.GlobalsService.pairMoves > 6 ? this.GlobalsService.pairMoves - 6 : 0));
  //   return nScore < 0 ? 0 : nScore;
  // }z
  getScore(): number{
    return Math.round(this.GlobalsService.pairScore);
  }
  back(): void{
    gameService.uploadScoreReport({gameRandomPieces: this.GlobalsService.pairRandomPieces, gameDisabledPieces: this.GlobalsService.pairDisabledPieces, gameMoves: this.GlobalsService.pairMoves, gamePairs: this.GlobalsService.pairPairs, gameStage: this.GlobalsService.pairStage, gameTimeSecond: this.GlobalsService.pairTime, gameScore: this.GlobalsService.pairScore, isDone:false, wrongAnswers: this.GlobalsService.totalAnswerd-this.GlobalsService.totalRightAnswered}).then(() =>{
      clearInterval(this.GlobalsService.pairInterval);
      this.router.navigateByUrl('dashboard');
    });
  }
}
