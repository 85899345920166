<button id='back' (click)="back()" mat-mini-fab color="primary" class='back-btn' style="display: none;">
    <mat-icon>arrow_back</mat-icon>
</button>
<div class="loading-spin-wrapper">
    <mat-spinner class="loadingSpin" *ngIf="loading"></mat-spinner>
</div>
<div [dir]="dir" *ngIf='!loading' style="margin-top: 5vh;height:68% ;width: 100%; margin-left: auto; margin-right: auto; overflow-y: scroll;overflow-x: hidden;">
    <div>
        <mat-list-item *ngFor="let question of questions; let i = index">
            <mat-card style="display: block;margin: auto;margin-top: 2vh;width: 80%;">
                <h2 style="width: 100%;">{{question}}</h2>
                <mat-slider
                    style="width: 100%;"
                    [formControl]="slidersControls.get(i + '')"
                    thumbLabel
                    tickInterval="1"
                    min="1"
                    max="10"></mat-slider>
            </mat-card>
        </mat-list-item>
        <mat-card style="display: block;margin: auto;margin-top: 2vh;width: 80%;">
            <mat-form-field  appearance="fill" class="example-full-width">
                <mat-label>{{translation.otherComments[language]}}</mat-label>
                <textarea [formControl]="commentsControl" matInput ></textarea>
            </mat-form-field>
        </mat-card>
        <div class="card-button-wrapper">
            <button class="button-general button-general-center" (click)="finish()" >{{translation.finish[language]}}</button>
        </div>        
    </div>
    
</div>
