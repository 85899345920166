import { Component, OnInit, Input } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import * as texts from '../../../assets/languages';
import {MatDialog} from '@angular/material/dialog';
import { AreYouDialogComponent } from '../dialogs/are-you-dialog/are-you-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() activityName: string;
  @Input() activityLogo: string;
  logoImg: string;
  translation:any;
  language:any;
  user:any;
  @Input() showMenuButtons: boolean = true;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private loginService: LoginService,
    private router: Router,) {
      this.translation = texts;
      this.language = localStorage.getItem('language');
      this.matIconRegistry.addSvgIcon(`icon_logout`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icon_logout.svg`));

      this.loginService.login().then(user => {
        this.user = user;
      });
   }

  ngOnInit(): void {
    this.loginService.login().then(user => {
      this.user = user;
      if(this.user) {
        this.firestore.collection('users').doc(this.user.uid).get().toPromise().then(_userDoc => {
          this.firestore.collection('activities').doc(_userDoc.data().currActivity).get().toPromise().then(doc => {
            this.logoImg = doc.data().activityLogo;
          });
        });
      }
    });
  }

  logoutFromActivity(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.leaveActivityTitle[this.language],
              content: this.translation.leaveActivityContent[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.firestore.collection('users').doc(this.user.uid).set({}).then(() => {
          localStorage.removeItem('tempCode');
          window.location.reload();
        });
      }
    });
  }

  logoutFromAccount(): void{
    const dialogRef = this.dialog.open(AreYouDialogComponent, {
      width: '400px',
      data: { title: this.translation.logoutTitle[this.language],
              ok: this.translation.logout[this.language]
            }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true){
        this.loginService.logout().then(() => {
          window.location.reload();
        });
      }
    });
  }

  back(): void {
    this.router.navigateByUrl('dashboard/course-map');
  }

}
