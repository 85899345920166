// tslint:disable: max-line-length

import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as texts from '../../../../assets/languages';
import { LoginService } from '../../../services/login.service';
import { AreYouDialogComponent } from '../../dialogs/are-you-dialog/are-you-dialog.component';
import { ComponentModel } from '../course-dashboard/course-dashboard.component';
import { ExplainDialogComponent } from '../explain-dialog/explain-dialog.component';
import { DashboardService } from './../../../services/dashboard.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-component-screen',
  templateUrl: './component-screen.component.html',
  styleUrls: ['./component-screen.component.css']
})

export class ComponentScreenComponent implements OnInit {
  language;
  translation;
  dir = 'rtl';
  user;
  currIndex;
  components: Array<ComponentModel> = new Array();
  activityCode;
  loading = true;
  isClicked = false;
  constructor(@Inject(DOCUMENT) private document: Document,
              private firestore: AngularFirestore,
              public dialog: MatDialog,
              private router: Router,
              private snackBar: MatSnackBar,
              private loginService: LoginService,
              private dashboardService: DashboardService) {
    this.translation = texts;
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
    this.loginService.login().then(user => {
      this.user = user;
      if (!(user && user.uid)){
        window.location.reload();
      }
      this.dashboardService.setUser(user);
      this.dashboardService.getActivityData().then(doc => {
        this.activityCode = doc.id;
        this.setScreen(doc.data().layoutId, doc.data().customerUID);
      });
    });
  }
  ngOnInit(): void {
  }
  moveToLink(url: any): void{
    this.isClicked = true;
    const win = window.open(url, '_blank');
    win.focus();
  }
  setScreen(layoutId: string, customerId: string): void{
    this.dashboardService.setCourseScreen(layoutId, customerId, this.activityCode).then(data => {
      this.components = data.localComponents;
      this.currIndex =  data.currComponentIndex;
      if (this.currIndex >= this.components.length){
        this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(this.activityCode).get().toPromise().then(userActivityDoc => {
          if (userActivityDoc.data().endTime != null){
            this.router.navigateByUrl('dashboard/course-end');
          }
          else{
            this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(this.activityCode).update({endTime: new Date()}).then(() => {
              this.router.navigateByUrl('dashboard/course-end');
            });
          }
        });
      }
      else{
        this.loading = false;
      }
    });
  }

  back(): void {
    this.router.navigateByUrl('dashboard/course-map');
  }
  next(): void {                                                                 
    const curComponent = this.components[this.currIndex].componentId;    /* check watching for video/presentaion before moving to next station */
    if( (curComponent=== 'U3yrqsw4kEGlJgkPeZxo' || curComponent==='5qXp60HM1svkMPK0RW8i' ) 
       && !this.isClicked){
        this.snackBar.open('עליך לצפות בסרטון/מצגת כדי להמשיך', '', {duration: 2000, direction: 'rtl' });
        return;
    }
    if (this.components[this.currIndex].configurations['gameId']){
      const dialogRef = this.dialog.open(ExplainDialogComponent, {
        width: '400px',
        data: {gameId: this.components[this.currIndex].configurations['gameId']}
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    else if (this.components[this.currIndex].componentId === 'ruvelQ6mbSE1Nm1W0dkc'){
      this.router.navigateByUrl('dashboard/course-survey');
    }
    else{
      const dialogRef = this.dialog.open(AreYouDialogComponent, {
        width: '400px',
        data: { title: this.translation.finishComponentTitle[this.language],
                content: this.translation.finishComponentContent[this.language],
                ok: this.translation.finish[this.language]
              }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true){
          this.loading = true;
          this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(this.activityCode).update({currComponent: this.currIndex + 1}).then(() => {
            this.router.navigateByUrl('dashboard/course-map');
          });
        }
      });
    }
  }
}



