import { LoginService } from '../../../services/login.service';
// tslint:disable: max-line-length
import { AngularFirestore } from '@angular/fire/firestore';
import { GlobalsService } from 'src/app/services/globals.service';
// Copyright © Marvsoft LLP | All Rights Reserved
import * as gameService from '../../../../assets/environments/service';

import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { PieceHolderComponent } from '../piece-holder/piece-holder.component';

@Component({
  selector: 'ms-game-board',
  templateUrl: './game-board.component.html',
  styleUrls: ['./game-board.component.scss']
})
export class GameBoardComponent implements AfterViewInit {
  currentGameID = 'jyeKPwy8f2CUmq1emCM9';
  nTotalStages: number = 3;
  bIsActive: boolean = false;
  strHour: string = '00';
  strMin: string = '00';
  strSec: string = '00';
  nInterval: any;
  @ViewChild(PieceHolderComponent, { static: false }) pieceHolder: PieceHolderComponent;

  constructor(public GlobalsService: GlobalsService) {
    this.startTimer();
    if (this.GlobalsService.pairScore === undefined){
      this.GlobalsService.pairScore = 0;
    }
    if (this.GlobalsService.totalAnswerd === undefined){
      this.GlobalsService.totalAnswerd = 0;
    }
    if (this.GlobalsService.totalRightAnswered === undefined){
      this.GlobalsService.totalRightAnswered = 0;
    }
   }

  ngAfterViewInit() {

    setTimeout(() => (document.getElementsByClassName('cGameContainer')[0] as HTMLElement).style.opacity = '1', 500);
  }

  // Start game
  startGame(first) {
    if (!first) {
      this.GlobalsService.pairRandomPieces = [];
      this.GlobalsService.pairDisabledPieces = [];
      this.GlobalsService.pairStage++;
      gameService.uploadScoreReport({
        gameRandomPieces: this.GlobalsService.pairRandomPieces,
        gameDisabledPieces: this.GlobalsService.pairDisabledPieces,
        gameMoves: this.GlobalsService.pairMoves,
        gamePairs: this.GlobalsService.pairPairs,
        gameStage: this.GlobalsService.pairStage,
        gameTimeSecond: this.GlobalsService.pairTime,
        gameScore: this.GlobalsService.pairScore,
        isDone:false,
        wrongAnswers: this.GlobalsService.totalAnswerd - this.GlobalsService.totalRightAnswered})
    }
    this.init();
    this.pieceHolder.init();
  }

  // Initialize the game
  init() {
    this.bIsActive = true;
    this.setTimerDisplay()

  }

  // Start timer
  startTimer() {
    this.GlobalsService.pairInterval = setInterval(() => this.updateTime(), 1000);
  }

  // Stop timer
  stopTimer() {
    clearInterval(this.nInterval);
  }

  // Update time
  updateTime() {
    this.GlobalsService.pairTime++;
    this.setTimerDisplay();
  }

  // Set timer
  setTimerDisplay() {
    let nSec: number = this.GlobalsService.pairTime % 60;
    let nMin: number = Math.floor(this.GlobalsService.pairTime / 60);
    let nHour: number = Math.floor(nMin / 60);
    nMin = nMin % 60;

    this.strHour = this.get2DigitFormattedVal(nHour);
    this.strMin = this.get2DigitFormattedVal(nMin);
    this.strSec = this.get2DigitFormattedVal(nSec);
  }

  //Get 2 digit formatted number
  get2DigitFormattedVal(p_nVal) {
    return p_nVal < 10 ? "0" + p_nVal : p_nVal + '';
  }


  // Update on game completed
  gameCompleted(data: {endStage: boolean, ansCorrectFirstAttempt: number}) {
    this.bIsActive = false;
    if (this.GlobalsService.pairStage < this.nTotalStages) {
      this.startGame(false);
    }
    else {
      this.GlobalsService.pairScore += data.ansCorrectFirstAttempt >=8 ? 4 : 0;
      gameService.uploadScoreReport({
        gameRandomPieces: this.GlobalsService.pairRandomPieces,
        gameDisabledPieces: this.GlobalsService.pairDisabledPieces,
        gameMoves: this.GlobalsService.pairMoves,
        gamePairs: this.GlobalsService.pairPairs,
        gameStage: this.GlobalsService.pairStage,
        gameTimeSecond: this.GlobalsService.pairTime,
        gameScore: this.GlobalsService.pairScore,
        isDone:true,
        wrongAnswers: this.GlobalsService.totalAnswerd - this.GlobalsService.totalRightAnswered}).then(() =>{
        window.location.href = '/#/dashboard';
      });

    }
  }
}
