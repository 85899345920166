import { ComponentModel } from './../.components/games.components/course-dashboard/course-dashboard.component';
import { Router } from '@angular/router';
import { Game } from './../.components/games.components/training-games/training-games.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
// tslint:disable: max-line-length
// tslint:disable: prefer-for-of

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  user = null;
  language;
  constructor(private firestore: AngularFirestore, private router: Router) { 
    this.language = localStorage.getItem('language');
  }
  getActivityData(): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>{
    return this.firestore.collection('users').doc(this.user.uid).get().toPromise().then(userDoc => {
      return this.firestore.collection('activities').doc(userDoc.data().currActivity).get().toPromise().then(doc => {
        if (doc.exists){
          if (doc.data()['end time'].toDate() < new Date()){
            return doc;
          }
          else{
            return doc;
          }
        }
      });
    });
  }
  setUser(user): void{
    this.user = user;
  }
  setGamesScreen(layoutId: string, customerId: string): Promise<any>{
    var localGamesArr = [];
    var locScore = 0;
    var localGames: Map<string, Game> = new Map();
    return this.firestore.collection('users').doc(this.user.uid).get().toPromise().then(userDoc => {
      return this.firestore.collection('activities').doc(userDoc.data().currActivity).get().toPromise().then( doc => {
        if (doc.exists) {
          localGamesArr = doc.data().gamesConfigurationSets;
          const baseIconPath = 'assets/images/';
          const promises = [];
          for (let index = 0; index < localGamesArr.length; index += 1) {
            const gameId: string = localGamesArr[index].gameId;
            let localScore = '0';
            promises.push(this.firestore.collection('scoreReport',ref => ref.where('activityId', '==', userDoc.data().currActivity).where('gameId', '==', gameId).where('userId', '==', this.user.uid).where('componentIndex', '==', index)).get().toPromise().then(scoreDocs => {
              let isEnable = true;
              const scoreDoc = scoreDocs.docs[0];
              if (scoreDoc && scoreDoc.exists){
                localScore = Math.round(scoreDoc.data().gameScore).toString();
                locScore += Math.round(scoreDoc.data().gameScore);
                if (scoreDoc.data().isDone){
                  isEnable = false;
                }
              }
              const gameSrc = baseIconPath + gameId + '.png';
              return this.firestore.collection('games').doc(gameId).get().toPromise().then(gameDoc => {
                localGames.set(gameId, { src: gameSrc, score: localScore, enable: isEnable, name: gameDoc.data().name[this.language]});
              });
            }));
          }
          return Promise.all(promises).then(() => {
            return {gamesArr: localGamesArr, score: locScore, gamesData: localGames};
          });
        }
      });
    });
  }
  setCourseScreen(layoutId: string, customerId: string, activityCode: string): Promise<any>{
    let localComponentsArr = [];
    const localComponents: Array<ComponentModel> = new Array();
    let currComponentIndex = 0;
    return this.firestore.collection('users').doc(this.user.uid).get().toPromise().then(userDoc => {
      return this.firestore.collection('activities').doc(activityCode).get().toPromise().then( doc => {
        if (doc.exists) {
          localComponentsArr = doc.data().componentsConfiguritionSets;
          const promises = [];
          for (let index = 0; index < localComponentsArr.length; index += 1) {
            const configId: string = localComponentsArr[index].configId;
            promises.push(this.firestore.collection('customers').doc(customerId).collection('componentsConfigurationSets').doc(configId).get().toPromise().then(configDoc => {
              localComponents.push({componentId: configDoc.data().componentId, configId, configurations: configDoc.data().configurations})
            }));
          }
          promises.push(this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(activityCode).get().toPromise().then(userActivityDoc => {
            currComponentIndex = userActivityDoc.data().currComponent;
          }));
          return Promise.all(promises).then(() => {
            return this.firestore.collection('scoreReport', ref => ref.where('activityId', '==', activityCode).where('userId', '==', this.user.uid).where('componentIndex', '==', currComponentIndex)).get().toPromise().then(scoreReportDocs => {
              if (scoreReportDocs.docs.length === 1){
                if (scoreReportDocs.docs[0].data().isDone){
                  return this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(activityCode).update({currComponent: currComponentIndex + 1}).then(() => {
                    this.router.navigateByUrl('dashboard/course-map');
                    currComponentIndex = currComponentIndex + 1;
                    return {localComponents, currComponentIndex};
                  });
                }
                else{
                  return this.firestore.collection('games').doc(scoreReportDocs.docs[0].data().gameId).get().toPromise().then(gameDoc => {
                    if (gameDoc.data().isFinishable === false){
                      return this.firestore.collection('users').doc(this.user.uid).collection('activities').doc(activityCode).update({currComponent: currComponentIndex + 1}).then(() => {
                        this.router.navigateByUrl('dashboard/course-map');
                        currComponentIndex = currComponentIndex + 1;
                        return {localComponents, currComponentIndex};
                      });
                    }
                    else{
                      return {localComponents, currComponentIndex};
                    }
                  });
                }
              }
              else{
                return {localComponents, currComponentIndex};
              }
            });
          });
        }
      });
    });
  }
}


