
<!-- <button *ngIf="!loading" mat-mini-fab color="primary" [matMenuTriggerFor]="menu" class='back-btn'>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu [dir]="dir" #menu="matMenu">
  <button mat-menu-item (click)="logoutFromActivity()">
      <mat-icon >autorenew</mat-icon>
      <span>{{translation.switchActivity[language]}}</span>
  </button>
  <button mat-menu-item (click)="logoutFromAccount()">
      <mat-icon >login</mat-icon>
      <span>{{translation.logoutFromAccount[language]}}</span>
  </button>
</mat-menu> -->
<div class="loading-spin-wrapper">
    <mat-spinner class="loadingSpin" *ngIf="loading"></mat-spinner>
</div>
<div *ngIf="!loading" style="justify-content: center;align-content: center;display: grid;margin-top:-5vh;height:63% ;width: 100%; margin-left: auto; margin-right: auto; overflow-y: hidden;overflow-x: hidden;">
    <div class="anim" class="games" [ngClass]="{'circle-container1': gamesArr.length === 1,'circle-container2': gamesArr.length === 2,'circle-container3': gamesArr.length === 3,'circle-container4': gamesArr.length === 4,'circle-container5': gamesArr.length === 5,'circle-container6': gamesArr.length === 6,'circle-container7': gamesArr.length === 7, 'circle-container8': gamesArr.length === 8, 'circle-container9': gamesArr.length === 9}" *ngIf="!loading" >
        <mat-list-item class="game" [ngClass]="{'startgames': startAnim}"  *ngFor="let gameId of gamesArr; let i = index">
         <div>
            <img [src]="games.get(gameId.gameId).src" class="gameLogo" [ngClass]="{'gameLogo1': gamesArr.length === 1,'gameLogo2': gamesArr.length === 2,'gameLogo3': gamesArr.length === 3,'gameLogo4': gamesArr.length === 4,'gameLogo5': gamesArr.length === 5,'gameLogo6': gamesArr.length === 6,'gameLogo7': gamesArr.length === 7, 'gameLogo8': gamesArr.length === 8, 'gameLogo9': gamesArr.length === 9}" (click)='showExplanation(gameId.gameId, i)'>
            <p class="anim" class="gameName" [ngClass]="{'gameName1': gamesArr.length === 1,'gameName2': gamesArr.length === 2,'gameName3': gamesArr.length === 3,'gameName4': gamesArr.length === 4,'gameName5': gamesArr.length === 5,'gameName6': gamesArr.length === 6,'gameName7': gamesArr.length === 7, 'gameName8': gamesArr.length === 8, 'gameName9': gamesArr.length === 9}">{{games.get(gameId.gameId).name}}</p>
            <p class="anim" class="gameScore" [ngClass]="{'gameScore1': gamesArr.length === 1,'gameScore2': gamesArr.length === 2,'gameScore3': gamesArr.length === 3,'gameScore4': gamesArr.length === 4,'gameScore5': gamesArr.length === 5,'gameScore6': gamesArr.length === 6,'gameScore7': gamesArr.length === 7, 'gameScore8': gamesArr.length === 8, 'gameScore9': gamesArr.length === 9}" (click)='showExplanation(gameId.gameId,i)'>{{games.get(gameId.gameId).score}}</p>
         </div>
        </mat-list-item >
    </div>
</div>


<div *ngIf="!loading" class="timer">
    <p id="timer">00:00:00</p>
</div>
<a *ngIf="!loading"><img id="leaderboard" (click)="moveToLeaderBoard()" class="leaderboardButton" align="left" src="assets/images/score-btn.png" /></a>
<span *ngIf="!loading" id="leaderboardEx" class="leaderboardEx">{{translation.leaderboard[language]}}</span>
<div *ngIf="!loading" id="finalScore" class="finalGrade"><p>{{score}}</p></div>
<span *ngIf="!loading" id="scoreEx" class="scoreEx">{{translation.score[language]}}</span>
