import { PackageData } from './../package/package.data';
import { FactoryService } from './../../../services/factory.service';
import * as gameService from '../../../../assets/environments/service';

// tslint:disable: prefer-for-of
// tslint:disable: no-angle-bracket-type-assertion 
// tslint:disable: max-line-length
// tslint:disable: radix

import {
  Component,
  AfterContentInit,
  ViewContainerRef,
  ComponentFactoryResolver,
  ViewChild,
  Inject,
  OnDestroy
} from '@angular/core';
import { PackageDirective } from './package.directive';
import { PackageComponent } from '../package/package.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { LOADIPHLPAPI } from 'dns';

export interface factoryQuestion{
  data: string;
  index: number;
}
@Component({
  selector: 'app-factory',
  templateUrl: './factory.component.html',
  styleUrls: ['./factory.component.scss'],
})
export class FactoryComponent implements AfterContentInit, OnDestroy {
  nCounter = 1;
  maxSpeed = 4;
  diff = 3.5;
  nMinTimeToLoadPkg = this.diff; 
  nMaxTimeToLoadPkg = this.nMinTimeToLoadPkg;
  nMaxPkgCount = 10000;
  viewContainerRef: ViewContainerRef;
  components: any = [];
  setTimeoutIntrv: any;
  start: number;
  remaining: number;
  nPrivacy = 100;
  nHappiness = 100;
  nWidth: number;
  isGameOver = false;
  nElapsedTime = 0;
  nInterval: any;
  strMin = '00';
  strSec = '00';
  nScore = 0;
  nSpeed = 1;
  MaxSpeed = false;
  nAcceleration = 0.5;
  user;
  currentGameID = 'NNdr1NRKTfWQlFwKfM5d';
  arrQuestions: factoryQuestion[] = [];
  arrAnswers: factoryQuestion[] = [];
  arr5MoreOptions: factoryQuestion[] = [];
  arrRndAnswers: factoryQuestion[] = [];
  nCurQnIndex = 0;
  nCurAnsIndex = 0;
  curQn: string;
  audFactory = new Audio('assets/audios/factory.mp3');
  audSiren: any = new Audio('assets/audios/fire_sirens.mp3');
  audCorrect: any = new Audio('assets/audios/clapping-hands.mp3');
  audWrong: any = new Audio('assets/audios/wrong.mp3');
  cDovePosition: {x: number, y: number};

  @ViewChild(PackageDirective, { static: true })  packageDirective: PackageDirective;
  
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private factoryService: FactoryService) {}

  getDataFromLayout(): Promise<any>{
    return gameService.loadGame(this.currentGameID).then(docs => {
      const scoreDoc = docs.scoreDoc;
      const ConfigDoc = docs.configurationDoc;
      this.factoryService.packages = [];
      this.factoryService.numOfQuestions = 0;
      this.factoryService.packagCounter = 0;
      this.factoryService.numOfCorrect = 0;
      this.factoryService.numOfWrong = 0;
      if (scoreDoc.exists){
        this.nElapsedTime = scoreDoc.data().gameTimeSecond.toString();
        this.arrRndAnswers  = scoreDoc.data().rndAnswers;
        let counter = 0;
        ConfigDoc.data().configurations.answers.forEach(element => {
          const localVar = {data: element, index: counter};
          counter++;
          this.arrAnswers.push(localVar);
        });
        /* keeps 5 first answers for displaying more answer options on last questins */
        this.arr5MoreOptions = this.arrAnswers.slice(0, 4);
        counter = 0;
        ConfigDoc.data().configurations.questions.forEach(element => {
          const localVar = {data: element, index: counter};
          counter++;
          this.arrQuestions.push(localVar);
        });
        this.nCurQnIndex = scoreDoc.data().QuestionsIndex;
        this.nScore = this.arrQuestions.length - scoreDoc.data().rndAnswers.length;
        this.factoryService.numOfCorrect = this.nScore - scoreDoc.data().wrongAnswers;
        this.factoryService.numOfWrong = scoreDoc.data().wrongAnswers;
        return true;
      }
      else{
        let counter = 0;
        ConfigDoc.data().configurations.answers.forEach(element => {
          const localVar = {data: element, index: counter};
          counter++;
          this.arrAnswers.push(localVar);
        });
        this.arr5MoreOptions = this.arrAnswers.slice(0, 4);
        counter = 0;
        ConfigDoc.data().configurations.questions.forEach(element => {
          const localVar = {data: element, index: counter};
          counter++;
          this.arrQuestions.push(localVar);
        });
        const nLen: number = this.arrAnswers.length;
        for (let i = 0; i < nLen; i++) {
          this.arrRndAnswers.push(this.arrAnswers[i]);
        }
        this.arrRndAnswers = this.randomArray(this.arrRndAnswers);
        return true;
      }
    });
  }
  ngAfterContentInit(): void { 
    this.getDataFromLayout().then( () => {
      this.factoryService.numOfQuestions = this.arrQuestions.length;
      this.factoryService.packagCounter = 0;
      this.viewContainerRef = this.packageDirective.viewContainerRef;
      if ((document.querySelector('.cDove') as HTMLElement) != null){
        (document.querySelector('.cDove') as HTMLElement).style.pointerEvents = 'none';
        const bounding: any = document.querySelector('.cDove').getBoundingClientRect();
        this.cDovePosition = { x: bounding.left + bounding.width / 2, y: bounding.top + bounding.height / 2 };
      }
      this.startGame();
      // this.nWidth = (document.querySelector('.cPrivacy .cProgress') as HTMLEmbedElement).scrollWidth;
    });
  }

  /* stopping the background sound when  exiting the component */
  ngOnDestroy(): void {
    this.audFactory.pause();
  }

  // Start game
  startGame(): void {
    // (p_event.currentTarget.parentNode as HTMLElement).style.display = 'none';
    this.init();
    this.audSiren.loop = true;
    this.audFactory.loop = true;
    this.audFactory.volume = 0.2;
    this.audFactory.play();
  }

  // Initialize the game
  init(): void {
    this.setTimerDisplay();
    this.startTimer();
    this.curQn = this.arrQuestions[this.nCurQnIndex].data;
    this.callNextPkg();
  }

  // Returns randomized array
  randomArray(arrTarget: factoryQuestion[]): factoryQuestion[] {
    const nLen = arrTarget.length;
    for (let i = 0; i < nLen; i++) {
      let nRnd: number = Math.floor(Math.random() * nLen);
      let strValue: factoryQuestion = arrTarget[nRnd];
      arrTarget[nRnd] = arrTarget[i];
      arrTarget[i] = strValue;
    }
    return arrTarget;
  }

  callNextPkg = () => {
    if (this.isGameOver) return;
    this.createComponent(this.nCounter++);
    if (this.nCounter < this.nMaxPkgCount) {
      this.remaining =
        this.getRandomNumberFromTo(
          this.nMinTimeToLoadPkg,
          this.nMaxTimeToLoadPkg
        ) * 1000;
      this.resume();
    }
  };

  pause = () => {
    clearTimeout(this.setTimeoutIntrv);
    this.remaining -= Date.now() - this.start;
  }

  resume = () => {
    if (this.isGameOver) return;

    this.start = Date.now();
    clearTimeout(this.setTimeoutIntrv);
    this.setTimeoutIntrv = setTimeout(this.callNextPkg, this.remaining);
  };

  getRandomNumberFromTo(nMin, nMax): number {
    return nMin + Math.random() * (nMax - nMin);
  }

  createComponent(nIndex): void {
    let packageData: PackageData = new PackageData();
    packageData.index = nIndex;
    packageData.message = this.arrRndAnswers[this.getNextAnsIndex()].data;
    if(this.arrRndAnswers.length < 5 ){ this.arrRndAnswers = this.arrRndAnswers.concat(this.arr5MoreOptions) }
    packageData.speed = this.nSpeed;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PackageComponent);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    let componentInstance = (<PackageComponent> componentRef.instance);
    componentInstance.action('index', { data: packageData });
    componentInstance.actionToParent.subscribe($event => { this.actionToParent($event); });
    this.components.push(componentRef);
  }

  getNextAnsIndex(): number {
    this.nCurAnsIndex++;
    this.nCurAnsIndex = this.nCurAnsIndex < this.arrRndAnswers.length ? this.nCurAnsIndex : 0;

    return this.nCurAnsIndex;
  }
  async speedDownAllActivePkg() {
    this.nSpeed -= this.nAcceleration;
    if(this.nSpeed < 1){
      this.nSpeed = 1;
      return
    }
    this.nMinTimeToLoadPkg = this.diff/this.nSpeed;
    this.nMaxTimeToLoadPkg = this.diff/this.nSpeed;

    for (let i = 0; i < this.components.length; i++) {
      const componentRef = this.components[i];
      (<PackageComponent>componentRef.instance).action('speedUp', this.nSpeed);
    }
  }

  async speedUpAllActivePkg() {
    this.nSpeed += this.nAcceleration;
    if(this.nSpeed > this.maxSpeed){
      this.nSpeed = this.maxSpeed;
      return
    }
    this.nMinTimeToLoadPkg = this.diff/this.nSpeed;
    this.nMaxTimeToLoadPkg = this.diff/this.nSpeed;
    console.log('nSpeed', this.nSpeed);
    for (let i = 0; i < this.components.length; i++) {
      const componentRef = this.components[i];
      (<PackageComponent>componentRef.instance).action('speedUp', this.nSpeed);
    }
  }

  
  async playAllActivePkg() {
    for (let i = 0; i < this.components.length; i++) {
      const componentRef = this.components[i];
      (<PackageComponent>componentRef.instance).action('play', '');
    }
  }
  
  async pauseAllActivePkg() {
    for (let i = 0; i < this.components.length; i++) {
      const componentRef = this.components[i];
      (<PackageComponent>componentRef.instance).action('pause', '');
    }
  }

  checkAns(message) {
    let nAnsIndex: number;
    this.arrAnswers.forEach( ans => {
      if (ans.data === message){
        nAnsIndex = ans.index;
      }
    });
    if (nAnsIndex === this.nCurQnIndex) {
      this.factoryService.numOfCorrect++;
      this.audCorrect.pause();
      this.audCorrect.currentTime = 1;
      this.audCorrect.play();
    }
    else {
      this.factoryService.numOfWrong++;
      this.audWrong.pause();
      this.audWrong.currentTime = 1;
      this.audWrong.play();
    }
    const question = this.arrQuestions[this.nCurQnIndex].data;
    const answer = this.arrAnswers[nAnsIndex].data;
    const correct = nAnsIndex === this.nCurQnIndex;

    gameService.uploadScoreReport({gameTimeSecond: parseInt(this.strMin) * 60 + parseInt(this.strSec), gameScore: this.factoryService.numOfCorrect * 100 / this.factoryService.numOfQuestions, wrongAnswers: this.factoryService.numOfWrong, QuestionsIndex: this.nCurQnIndex, rndAnswers: this.arrRndAnswers});
    gameService.uploadDetailedReport(this.nCurQnIndex, {question: question, userAnswer: answer, isCorrectAnswer: correct});

    return nAnsIndex == this.nCurQnIndex;
  }

  back(isDone: boolean): void{
    gameService.uploadScoreReport({gameTimeSecond: parseInt(this.strMin) * 60 + parseInt(this.strSec), gameScore: this.factoryService.numOfCorrect * 100 / this.factoryService.numOfQuestions, wrongAnswers: this.factoryService.numOfWrong, QuestionsIndex: this.nCurQnIndex, rndAnswers: this.arrRndAnswers, isDone: isDone}).then( () => {
      window.location.href = '/#/';
      this.audFactory.pause();
    });
  }
  removePackage(event: any) {
    // Find the component
    const component = this.components.find(
      (component) => component.instance === event
    );
    const componentIndex = this.components.indexOf(component);
    if (componentIndex !== -1) {
      // Remove component from both view and array
      this.viewContainerRef.remove(componentIndex);
      this.components.splice(componentIndex, 1);
    }
  }

  alertNow() {
    (document.querySelector('.cDove') as HTMLElement).style.pointerEvents = 'auto';
    let nLeft: number = (document.querySelector('.cDove') as HTMLElement).getBoundingClientRect().left / window.innerWidth * 100;
    let nTop: number = (document.querySelector('.cDove') as HTMLElement).getBoundingClientRect().top / window.innerHeight * 100;
    const moveLeftTimer = setInterval(() => {
      nLeft--;
      if(nLeft >= 43)
        (document.querySelector('.cDove') as HTMLElement).style.left = nLeft + '%';
      else
        clearInterval(moveLeftTimer);
    }, 50);
    
    let moveBottomTimer = setInterval(() => {
      nTop++;

      if(nTop <= 33)
        (document.querySelector('.cDove') as HTMLElement).style.top = nTop + '%';
      else
        clearInterval(moveBottomTimer);
    }, 50);
    // (document.querySelector('.cDove') as HTMLElement).style.left = '43%';
    // (document.querySelector('.cDove') as HTMLElement).style.top = '33%';

    this.actionToParent(['pause']);
    // this.pauseAllActivePkg()
    this.audFactory.pause();
    this.audSiren.play();
    (document.querySelector('.cError') as HTMLElement).style.display = 'block';
    (document.querySelector('.blinking') as HTMLElement).style.display = 'block';
  }

  nextQuestion() {
    this.nCurQnIndex++;
    this.nScore++;

    if(this.nCurQnIndex < this.arrQuestions.length) {
      if(this.nCurQnIndex % 4 == 0) 
        this.speedUpAllActivePkg();

      if(this.nCurQnIndex % 5 == 0) 
        this.alertNow();

      this.curQn = this.arrQuestions[this.nCurQnIndex].data;
    }
    else {
      this.isGameOver = true;
      this.audFactory.pause();
      this.stopTimer();

      setTimeout(() => {
        this.viewContainerRef.clear();
        // this.pause();
        this.back(true);
      }, 500);
    }
  }
  getRightAnswer(): void{
    this.arrQuestions.forEach( CurrQuestion => {
      if (CurrQuestion.data === this.curQn){
        this.factoryService.packages.forEach( currPackage => {
          if (currPackage.packageData.message === this.arrAnswers[CurrQuestion.index].data){
            this.removePackage(currPackage);
          }
        });
      }
    });
  }
  removePackageFromArr(): void{
    this.arrQuestions.forEach( CurrQuestion => {
      if (CurrQuestion.data === this.curQn){
        const localArr = this.arrRndAnswers;
        this.arrRndAnswers = [];
        localArr.forEach( currAnswer => {
          if (currAnswer.index !== CurrQuestion.index){
            this.arrRndAnswers.push(currAnswer);
          }
        });
      }
    });
  }
  async handleChekcAns(answer){
    await this.checkAns(answer);
    await this.removePackageFromArr();
    await this.getRightAnswer();
    await this.nextQuestion();
  }
  actionToParent = (actionData: any) => {
    if (this.isGameOver) return;

    switch (actionData[0]) {
      case 'checkAns':
        this.handleChekcAns(actionData[1][1])
        break;

      case 'removeMe':
        this.removePackage(actionData[1][0]);
        break;

      case 'pause':
        this.pause();
        this.pauseAllActivePkg();
        break;

      case 'play':
        this.resume();
        this.playAllActivePkg();
        break;
    }
  };

  // Start timer
  startTimer() {
    this.nInterval = setInterval(() => this.updateTime(), 1000);
  }

  // Stop timer
  stopTimer() {
    clearInterval(this.nInterval);
  }

  // Update time
  updateTime() {
    this.nElapsedTime++;
    this.setTimerDisplay();
  }

  // Set timer
  setTimerDisplay() {
    let nSec: number = this.nElapsedTime % 60;
    let nMin: number = Math.floor(this.nElapsedTime / 60);
    // let nHour: number = Math.floor(nMin / 60);
    nMin = nMin % 60;

    // this.strHour = this.get2DigitFormattedVal(nHour);
    this.strMin = this.get2DigitFormattedVal(nMin);
    this.strSec = this.get2DigitFormattedVal(nSec);
  }

  //Get 2 digit formatted number
  get2DigitFormattedVal(p_nVal) {
    return p_nVal < 10 ? '0' + p_nVal : p_nVal + '';
  }

  // Returns score.
  getScore() {
    let qNum = this.nScore + 1;
    if(qNum>this.arrAnswers.length) { qNum-- };
    return qNum + '/' + this.arrAnswers.length;
  }

  getGameScore() {
    const correctAnswers = this.factoryService.numOfCorrect;   /* not to get negative score */
    if (correctAnswers > 0){  
    return Math.round(this.factoryService.numOfCorrect * 100 / this.factoryService.numOfQuestions);
    } else { return 0; }
  }
  // Action after drag ended.
  dragEnded(event: CdkDragEnd) {
    let dragItem: HTMLElement = event.source.element.nativeElement as HTMLElement;
    // let nItemIndex: number = parseInt(dragItem.id.split('iDrag')[1]);
    let nItemLeft: number = dragItem.getBoundingClientRect().left + dragItem.getBoundingClientRect().width / 2;
    let nItemTop: number = dragItem.getBoundingClientRect().top + dragItem.getBoundingClientRect().height / 2;

    if (Math.abs(nItemLeft - this.cDovePosition.x) < 30 && Math.abs(nItemTop - this.cDovePosition.y) < 30) {
      dragItem.style.transform = '';
      // event.source.disabled = true;
      (document.querySelector('.cDove') as HTMLElement).style.pointerEvents = 'none';
      dragItem.style.left = this.cDovePosition.x - dragItem.getBoundingClientRect().width / 2 + 'px';
      dragItem.style.top = this.cDovePosition.y - dragItem.getBoundingClientRect().height / 2 + 'px';

      this.actionToParent(['play']);
      this.audFactory.play();
      this.audSiren.pause();
      (document.querySelector('.cError') as HTMLElement).style.display = 'none';
      (document.querySelector('.blinking') as HTMLElement).style.display = 'none';
    }
  }
}

@Component({
  selector: 'popup-dialog',
  templateUrl: 'popup-dialog.html',
})
export class PopupDialog {
  constructor(
    public dialogRef: MatDialogRef<PopupDialog>,
    @Inject(MAT_DIALOG_DATA) public message: string
  ) {}
  close(message){
    this.dialogRef.close(message);
  }
}
