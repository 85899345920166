<!-- Copyright © Marvsoft LLP | All Rights Reserved -->

<p id="back" style="width: 8vw; height: 8vw; font-size: xx-large;" (click)="back()">X</p>
<div class="cGameContainer" dir='rtl'>
    <div class="cLogo"></div>
    <div class="cQuestionHolder">
        <div class="cQnAnim">
            <div class="cQuestion animation">{{getCurrentQuestion()}}</div>
        </div>
        <mat-radio-group aria-label="Select an option" (change)="checkAnswer($event)" [(ngModel)]="selectedOption">
            <mat-radio-button value="true" class="cLeftIn">True</mat-radio-button>
            <mat-radio-button value="false" class="cRightIn">False</mat-radio-button>
        </mat-radio-group>

        <div id="iCorrect" class="cFadeIn"></div>
        <div id="iWrong" class="cFadeIn"></div>
    </div>
</div>